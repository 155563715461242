import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CaseMapKeyHistory } from '@Models';

import { ExecutionLog, GenerateDocumentStatusModel, ProcessInterviewTask } from './models/execution-log.interface';
import { CaseMetricService } from './services/case-metrics.service';
import { from, mergeMap, Observable, of, toArray } from 'rxjs';
import { AppService } from '@App/index';
import { UserAdminService } from '@Services';

@Component({
    selector: 'case-metrics-v2',
    templateUrl: './case-metrics-v2.component.html',
    styleUrls: ['./case-metrics-v2.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])]
})
export class CaseMetricsV2Component implements OnInit{
    @Input() caseId;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    currentTab = '';
    filterValue: string;
    executionLogs: ExecutionLog[] = [];
    documentExecutionLogs: GenerateDocumentStatusModel[] = [];
    stpExecutionLogs: ProcessInterviewTask[] = [];
    caseMapkeys: CaseMapKeyHistory[] = [];

    columnsToDisplay = ["evaluationType", "sectionName", "operationName", "name", "executionDate", "duration"];
    documentColumnsToDisplay = ["documentName", "success", "message", "sizeInBytes", "startDateTime", "endDateTime", "duration"];
    stpColumnsToDisplay = ["step", "task", "element", "status", "processDateTime"];
    mapKeyColumnsToDisplay = ["name", "value", "lastModifiedDate", "lastModifiedBy"];

    objectColumnsToDisplay = ["operationName", "SectionName", "ActionName", "ObjectName", "EllapsedMilliseconds"];
    ruleColumnsToDisplay = ["Name", "RuleResult", "Duration"];
    vmkVolumnsToDisplay = ["Expression", "MapKeyName", "Result"];

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    docmumentDataSource: MatTableDataSource<GenerateDocumentStatusModel> = new MatTableDataSource<GenerateDocumentStatusModel>();
    JSON;

    noDataPlaceholder = false;
    users: any[];

    constructor(private caseMetricService: CaseMetricService,
        public appService: AppService,
        public userAdminService: UserAdminService) {
        this.JSON = JSON;
    }

    ngOnInit() {
        this.userAdminService.getAllUsers().subscribe(x => this.users = x);
    }
    
    tabChange(textLabel) {
        if (textLabel === this.currentTab){
            return;
        }

        if (textLabel === 'Rules') {
            this.getRuleExecutionLogs();
            this.currentTab = 'Rules';
        }

        if (textLabel === 'VMKs') {
            this.getVmkExecutionLogs();
            this.currentTab = 'VMKs';
        }

        if (textLabel === 'Documents') {
            this.getDocumentExecutionLogs();
            this.currentTab = 'Documents';
        }

        if (textLabel === 'STP') {
            this.getSTPLogs();
            this.currentTab = 'STP';
        }

        if (textLabel === 'Mapkey History') {
            this.getMapkeyHistory();
            this.currentTab = 'Mapkey History';
        }
    }

    refresh() {
        if (this.currentTab === 'Documents') {
            this.getDocumentExecutionLogs();
            return;
        }

        if (this.currentTab === 'STP') {
            this.getSTPLogs();
            return;
        }

        if (this.currentTab === 'Mapkey History') {
            this.getMapkeyHistory();
            return;
        }

        if (this.currentTab === 'Rules') {
            this.getRuleExecutionLogs();
            return;
        }

        if (this.currentTab === 'VMKS') {
            this.getVmkExecutionLogs();
            return;
        }
    }

    getRuleExecutionLogs() {
        this.dataSource = new MatTableDataSource();
        this.appService.display(true);
        this.caseMetricService.getRuleExecutionLogPaths(this.caseId).subscribe(result => {  
            const observablesList:Observable<ExecutionLog[]>[] = [];

            result.forEach(path => {
                observablesList.push(this.caseMetricService.getRuleExecutionLog(this.caseId, path));
            });

            let allResults = [];
            from(observablesList)
                .pipe(mergeMap(observable => observable, 3))
                .subscribe({
                    next: (partialResults) => {
                        allResults = allResults.concat(partialResults);
                        this.appService.display(true);
                    },
                    complete: () => {
                        this.executionLogs = allResults;

                        if (!result || result === null || result.length === 0){
                            this.noDataPlaceholder = true;
                        }else{
                            this.noDataPlaceholder = false;
                        }
    
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.data = this.executionLogs;            
                        this.dataSource.sort = this.sort;
                        this.dataSource.filterPredicate = this.tableFilter();
    
                        this.dataSource.sortingDataAccessor = (item, property) => {
    
                            if (property == 'evaluationType' && item.ruleEvaluationModel && item.ruleEvaluationModel !== null) {
                                return item.ruleEvaluationModel.evaluationType;
                            }
        
                            if (property == 'sectionName' && item.ruleEvaluationModel && item.ruleEvaluationModel !== null) {
                                return item.ruleEvaluationModel.sectionName;
                            }
        
                            if (property == 'operationName' && item.ruleEvaluationModel && item.ruleEvaluationModel !== null) {
                                return item.ruleEvaluationModel.operationName;
                            }
        
                            if (property == 'duration' && item.ruleEvaluationModel && item.ruleEvaluationModel !== null) {
                                return item.ruleEvaluationModel.duration;
                            }
        
                            return item[property];
        
                        };

                        this.appService.display(false);
                    }
                });
            
        });
    }

    getVmkExecutionLogs() {
        this.dataSource = new MatTableDataSource();
        this.caseMetricService.getVmkExecutionLogPaths(this.caseId).subscribe(result => {
            const observablesList:Observable<ExecutionLog[]>[] = [];

            result.forEach(path => {
                observablesList.push(this.caseMetricService.getVmkExecutionLog(this.caseId, path));
            });

            let allResults = [];
            from(observablesList)
                .pipe(mergeMap(observable => observable, 3))
                .subscribe({
                    next: (partialResults) => {
                        allResults = allResults.concat(partialResults);
                        this.appService.display(true);
                    },
                    complete: () => {
                        this.executionLogs = allResults;

                        if (!result || result === null || result.length === 0){
                            this.noDataPlaceholder = true;
                        }else{
                            this.noDataPlaceholder = false;
                        }
            
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.data = this.executionLogs;
                        this.dataSource.sort = this.sort;
                        this.dataSource.filterPredicate = this.tableFilter();
            
                        this.dataSource.sortingDataAccessor = (item, property) => {
            
                            if (property == 'evaluationType' && item.vmkEvaluationModel && item.vmkEvaluationModel !== null) {
                                return item.vmkEvaluationModel.evaluationType;
                            }
                
                            if (property == 'sectionName' && item.vmkEvaluationModel && item.vmkEvaluationModel !== null) {
                                return item.vmkEvaluationModel.sectionName;
                            }
                    
                            if (property == 'operationName' && item.vmkEvaluationModel && item.vmkEvaluationModel !== null) {
                                return item.vmkEvaluationModel.operationName;
                            }
                    
                            if (property == 'duration' && item.vmkEvaluationModel && item.vmkEvaluationModel !== null) {
                                return item.vmkEvaluationModel.duration;
                            }
                
                            return item[property];
                
                        };

                        this.appService.display(false);
                    }
                });
                  
        });
    }

    getSTPLogs() {
        this.dataSource = new MatTableDataSource();
        this.caseMetricService.getSTPExecutionLog(this.caseId).subscribe(result => {     
            if (!result || result === null || result.length === 0){
                this.noDataPlaceholder = true;
            }else{
                this.noDataPlaceholder = false;
            }
            
            result.forEach(x => {
                if (x.message)
                    x.message = JSON.parse(x.message);
            });

            this.stpExecutionLogs = result;

            this.dataSource.paginator = this.paginator;
            this.dataSource.data = this.stpExecutionLogs;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.stpFilter();
        });
    }

    getMapkeyHistory() {
        this.dataSource = new MatTableDataSource();
        
        this.caseMetricService.getCaseMapkeyHistory(this.caseId).subscribe(result => {
            this.caseMapkeys = result;
            this.caseMapkeys.forEach(x => {
                const user = this.users.find(user => user.id == x.lastModifiedBy);
                x.lastModifiedBy = user?.name;

                x.caseMapkeys.forEach(h => {
                    const user = this.users.find(user => user.id == h.lastModifiedBy);
                    h.lastModifiedBy = user?.name;
                });
            });

            if (!result || result === null || result.length === 0){
                this.noDataPlaceholder = true;
            }else{
                this.noDataPlaceholder = false;
            }
            
            this.dataSource.paginator = this.paginator;
            this.dataSource.data = this.caseMapkeys;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.caseMapkeyFilter();
        });
    }

    getDocumentExecutionLogs() {
        this.dataSource = new MatTableDataSource();

        this.caseMetricService.getDocumentExecutionLog(this.caseId).subscribe(result => {
            this.documentExecutionLogs = result;

            if (!result || result === null || result.length === 0){
                this.noDataPlaceholder = true;
            }else{
                this.noDataPlaceholder = false;
            }
            
            this.dataSource.paginator = this.paginator;
            this.dataSource.data = this.documentExecutionLogs;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.documentFilter();
        });
    }


    searchLogs() {
        if (this.filterValue) {
            this.dataSource.filter = this.filterValue.trim().toLowerCase();
        } else {
            this.dataSource.filter = null;
        }
    }

    documentFilter(): (data: any, filter: string) => boolean {
        return function (data, filter): boolean {
            let result = false;

            result = data.documentName && data.documentName?.toLowerCase().indexOf(filter) !== -1
                || data.message && data.message?.toLowerCase().indexOf(filter) !== -1;

            return result;
        };
    }


    stpFilter(): (data: any, filter: string) => boolean {
        return function (data, filter): boolean {
            let result = false;

            result = data.element && data.element?.toLowerCase().indexOf(filter) !== -1
                || data.task && data.task?.toLowerCase().indexOf(filter) !== -1
                || data.status && data.status?.toLowerCase().indexOf(filter) !== -1;

            return result;
        };
    }

    caseMapkeyFilter(): (data: any, filter: string) => boolean {
        return function (data, filter): boolean {
            let result = false;

            result = data.name && data.name?.toLowerCase().indexOf(filter) !== -1;

            return result;
        };
    }
    tableFilter(): (data: any, filter: string) => boolean {
        return function (data, filter): boolean {
            let result = false;
            if (data.ruleEvaluationModel) {
                result = (data.ruleEvaluationModel.evaluationType && data.ruleEvaluationModel.evaluationType.toLowerCase().indexOf(filter) !== -1)
                    || (data.ruleEvaluationModel.sectionName && data.ruleEvaluationModel.sectionName.toLowerCase().indexOf(filter) !== -1)
                    || (data.ruleEvaluationModel.operationName && data.ruleEvaluationModel.operationName.toLowerCase().indexOf(filter) !== -1)
                    || data.name.toLowerCase().indexOf(filter) !== -1
                    || data.metricType.toLowerCase().indexOf(filter) !== -1;
            }

            if (data.vmkEvaluationModel) {
                result = data.vmkEvaluationModel.evaluationType?.toString().toLowerCase().indexOf(filter) !== -1
                    || (data.vmkEvaluationModel.sectionName && data.vmkEvaluationModel.sectionName.toLowerCase().indexOf(filter) !== -1)
                    || (data.vmkEvaluationModel.operationName && data.vmkEvaluationModel.operationName.toLowerCase().indexOf(filter) !== -1)
                    || data.name?.toLowerCase().indexOf(filter) !== -1
                    || data.metricType?.toLowerCase().indexOf(filter) !== -1;
            }

            return result;
        };
    }

    diffSeconds(dt2, dt1) {
        const diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
        return Math.abs(Math.round(diff));
    }

    diffMilliSeconds(dt2, dt1) {
        const diff = (new Date(dt2).getTime() - new Date(dt1).getTime());
        return Math.abs(Math.round(diff));
    }

    sortColumn(columnName) {
        this.sort.active = columnName;
        this.sort.sort({ id: columnName, start: this.sort.start, disableClear: false });
    }
}

export function limitedParallelObservableExecution<T>(
    listOfItems: Array<T>,
    observableMethod: (item: T) => Observable<any>,
    maxConcurrency = 4
): Observable<any> {
    if (listOfItems && listOfItems.length > 0) {
        const observableListOfItems: Observable<T> = from(listOfItems);
        return observableListOfItems.pipe(
            mergeMap(observableMethod, maxConcurrency),
            toArray()
        );
    } else {
        return of({});
    }
}
