<div class="mx-auto w-100">
  <div class="page-borders">
    <div class="flex flex-row main-page-heading ">
      <div>General Case Settings</div>
      <div class="flex-1"></div>
      <div style="justify-content: flex-end; padding-bottom: 5px;">
        <mrs-btn-tertiary
          *ngIf="generalSettingsForm.dirty"
          (onClick)="discardSettings()"
          buttonId="discardChangesButton"
          buttonLabel="Discard Changes"
        >
        </mrs-btn-tertiary>
        <mrs-btn-primary
          (onClick)="saveGeneralSettings()"
          *ngIf="checkACL('W')"
          buttonId="saveButton"
          buttonLabel="Save"
          [disabled]="generalSettingsForm.pristine"
          class="float-right">
        </mrs-btn-primary>
      </div>
    </div>


    <div>
      <form [formGroup]="generalSettingsForm" novalidate autocomplete="off">
        <input type="hidden" formControlName='accountId'>

        <div class="page-sub-header">
          Automatic Cancellation
        </div>
        <mat-card appearance="outlined">
          <mat-card-content *ngIf=!autoCancelByRule>
            <div class="sub-heading">Rule</div>
            <div>Runs nightly.</div>
            <div class="flex flex-row">
              <div style="margin-top: 25px; margin-right: 10px;">Cancel On Hold cases after</div>
              <div>
                <mat-form-field style="width: 60px; ">
                  <input matInput type="number" formControlName="cancelOnHoldCasesInterval"/>
                </mat-form-field>
              </div>
              <div>
                <mat-label>Minutes/Hours/Days</mat-label>
                <mat-select style="margin-top: 25px; margin-left: 10px;" formControlName='cancelOnHoldCasesIntervalType'>
                  <mat-option value="Minutes">Minutes</mat-option>
                  <mat-option value="Hours">Hours</mat-option>
                  <mat-option value="Days">Days</mat-option>
                </mat-select>
              </div>
            </div>
          </mat-card-content>
          <mat-card-content *ngIf=autoCancelByRule>
            <div class="sub-heading">Rule</div>
            <div>Runs nightly. Cases where the rule is satisfied will be cancelled.</div>
            <div>
              <mat-form-field style="width: 360px"
                              floatLabel="auto">
                <mat-label>Add a Rule</mat-label>
                <input matInput
                       [matAutocomplete]="auto"
                       #trigger="matAutocompleteTrigger"
                       type="text"
                       formControlName="cancelCasesRuleId">
                <span matSuffix *ngIf="true" (click)="trigger.openPanel()" style="cursor: pointer;"><mat-icon
                  svgIcon="menu-down"></mat-icon></span>
              </mat-form-field>
              <mat-autocomplete
                #auto="matAutocomplete"
                autoActiveFirstOption
                [displayWith]="getRuleOptionText.bind(this)">
                <mat-option *ngFor="let rule of filteredRules | async" [value]="rule.id" [id]="rule.name"
                            [matTooltip]="rule.name" matTooltipPosition="left">
                  {{rule?.name}}
                </mat-option>
              </mat-autocomplete>
            </div>

            <div *ngIf="!isControlEmpty('cancelCasesRuleId')" style="padding-top:10px">
              <div class="sub-heading">Case Status Change Note</div>
              <div *ngIf="toggleHtmlEditorFeature">
                <angular-editor id="angularEditor"
                                [config]="htmlEditorConfig"
                                [placeholder]="noteDefaultMessage"
                                formControlName="cancelCasesNote"></angular-editor>
                <mat-error *ngIf="generalSettingsForm.get('cancelCasesNote').hasError('required')">Note Text is required</mat-error>
              </div>
              <div *ngIf="!toggleHtmlEditorFeature">
                <mat-form-field class="w-100 textarea-formfield">
                  <mat-label>Note Text</mat-label>
                  <textarea id="textAreaEditor"
                            matInput
                            matTextareaAutosize
                            matAutosizeMinRows="3"
                            matAutosizeMaxRows="5"
                            formControlName="cancelCasesNote"></textarea>
                  <mat-error>Note Text is required</mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="page-sub-header mt-3">
          Purge
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <div class="flex w-100" style="flex-direction: column">
              <div class="sub-heading">Rule</div>
              <div>Runs nightly.</div>
              <div class="flex flex-column purge-box">
                <div class="flex flex-row" style="display: inline-block">
                  Purge <b>Done</b> and <b>Cancelled</b> cases
                  <mat-form-field style="width: 5em">
                    <input id="casePurgeInput" matInput type="number" formControlName="casePurgeDaysBack" min="1"/>
                  </mat-form-field>
                  days after the case was <b>created</b>.
                </div>
                <div>
                  <mat-error
                    *ngIf="generalSettingsForm.get('casePurgeDaysBack').hasError('min')">
                    Number of days must be greater than 0.
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="flex flex-row mt-3"
                 *ngIf="generalSettingsForm.get('casePurgeDaysBack').value !== '' && generalSettingsForm.get('casePurgeDaysBack').value > 0"
                 [@flyRightToLeft]>
              <div class="flex" style="flex-direction: column; width: 200px">
                <div class="sub-heading" style="margin-bottom: 0 !important">Export Data</div>
                <div>PII data de-identified and archived upon purge.</div>
              </div>
              <mat-slide-toggle class="sub-heading d-inline-block" formControlName="caseExportBeforePurge">
              </mat-slide-toggle>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="page-sub-header mt-3">
          Workload Management
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <div class="flex flex-row">
              <div class="flex" style="flex-direction: column; width: 200px">
                <div class="sub-heading" style="margin-bottom: 0 !important">Case Assignment</div>
                <div>Assign cases from Case Manager or Case Summary.</div>
              </div>
              <mat-slide-toggle class="sub-heading d-inline-block" formControlName="allowCaseAssignment">
              </mat-slide-toggle>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="page-sub-header mt-3">
          Case Summary
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <div class="flex w-100" style="flex-direction: column">
              <div class="sub-heading" style="margin-bottom: 0 !important">Agent Message</div>
              <div>Display a persistent message at the top of Case Summary for Agents.</div>
              <mat-form-field class="w-100" [floatLabel]="'never'">
                <mat-label>Agent Warning Message</mat-label>
                <textarea matInput formControlName="agentWarningMessage"
                          rows="3"></textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="page-sub-header mt-3">
          Troubleshooting
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <div class="flex flex-row">
              <div class="flex" style="flex-direction: column; width: 200px">
                <div class="sub-heading" style="margin-bottom: 0 !important">Disable Call Masking</div>
                <div>Allow users without call provider credentials to complete and test cases.</div>
              </div>
              <mat-slide-toggle class="sub-heading d-inline-block" formControlName="disableCallMasking">
              </mat-slide-toggle>
            </div>
          </mat-card-content>
        </mat-card>

        <div *ngIf="useDocGenV2Setting || useDistributedConfigCacheSetting">
          <div class="page-sub-header mt-3">
            Experimental
          </div>
          <mat-card appearance="outlined" *ngIf="useDocGenV2Setting">
            <mat-card-content>
              <div class="flex flex-row">
                <div class="flex" style="flex-direction: column; width: 250px">
                  <div class="sub-heading" style="margin-bottom: 0 !important">Document Generation V2</div>
                  <div>Use v2 of document generation.</div>
                </div>
                <mat-slide-toggle class="sub-heading d-inline-block" formControlName="useDocGenV2">
                </mat-slide-toggle>
              </div>
              <div class="flex flex-row" *ngIf="generalSettingsForm.get('useDocGenV2').value === true">
                <div class="flex" style="flex-direction: column; width: 250px">
                  <div class="sub-heading" style="margin-bottom: 0 !important">Stealth Mode</div>
                  <div>Use v2 document generation in stealth mode which will log results but still use v1 to produce the documents.</div>
                </div>
                <mat-slide-toggle class="sub-heading d-inline-block" formControlName="useDocGenV2Stealth">
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="useDistributedConfigCacheSetting">
            <mat-card-content>
              <div class="flex flex-row">
                <div class="flex" style="flex-direction: column; width: 250px">
                  <div class="sub-heading" style="margin-bottom: 0 !important">Caching</div>
                  <div>Use distributed config cache</div>
                </div>
                <mat-slide-toggle class="sub-heading d-inline-block" formControlName="useDistributedConfigCache">
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="cacheAccountByEntitySetting">
            <mat-card-content>
              <div class="flex flex-row">
                <div class="flex" style="flex-direction: column; width: 250px">
                  <div class="sub-heading" style="margin-bottom: 0 !important">Caching</div>
                  <div>Cache Account by Entity</div>
                </div>
                <mat-slide-toggle class="sub-heading d-inline-block" formControlName="cacheAccountByEntity">
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="optimizeRuleAndVmkProcessingSetting">
            <mat-card-content>
              <div class="flex flex-row">
                <div class="flex" style="flex-direction: column; width: 250px">
                  <div class="sub-heading" style="margin-bottom: 0 !important">Rules and Vmks</div>
                  <div>Optimize rule and vmk processing</div>
                </div>
                <mat-slide-toggle class="sub-heading d-inline-block" formControlName="optimizeRuleAndVmkProcessing">
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="useDocusignV2Setting">
            <mat-card-content>
              <div class="flex flex-row">
                <div class="flex" style="flex-direction: column; width: 250px">
                  <div class="sub-heading" style="margin-bottom: 0 !important">Docusign</div>
                  <div>Use v2 of Docusign in the application</div>
                </div>
                <mat-slide-toggle class="sub-heading d-inline-block" formControlName="useDocusignV2">
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        
      </form>
    </div>
  </div>
</div>
