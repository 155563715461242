<div class="page-heading" id="subheading">
  <span class="page-heading-title">System</span>
  <div class="d-inline float-right">
    <button class="mr-2 float-left" mat-button color="basic" (click)="flushCache()">
      <i aria-hidden="true" class="fa fa-sync-alt"></i> Flush Cache
    </button>
    <button class="mr-2 float-left" mat-button color="basic" (click)="flushPublicApiCache()">
      <i aria-hidden="true" class="fa fa-sync-alt"></i> Flush Public API Cache
    </button>
    <button class="mr-2 float-left" mat-button color="basic" (click)="flushFunctionCache()">
      <i aria-hidden="true" class="fa fa-sync-alt"></i> Flush Function Caches
    </button>
    <button class="mr-2 float-left" mat-button color="basic" (click)="resetShardMap()">
      <i aria-hidden="true" class="fa fa-sync-alt"></i> Reset Shard Map
    </button>
  </div>
</div>
<mat-tab-group>
  <mat-tab label="Import/Export">
    <div id="innercontent" class="custom-scrollbar flex flex-row" style="flex-wrap: wrap">
      <mat-card appearance="outlined" class="system-card mt-3">
        <mat-card-content>
          <form #exportCaseForm="ngForm">
            <h2>Export Case</h2>
            <div class="form-group w-100">
              <mat-form-field class="w-100">
                <mat-select placeholder="Client" [(ngModel)]="exportCaseAccountClient" id="exportCaseAccount"
                  name="exportCaseAccount" #exportAccount="ngModel">
                  <mat-option *ngFor="let client of clients" [value]="client">
                    {{client.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group w-100">
              <mat-form-field class="w-100">
                <input type="text" [(ngModel)]="exportCaseModel" id="exportCaseId" name="exportCaseId"
                  #exportCaseId="ngModel" placeholder="Case Number to export" matInput>
              </mat-form-field>
            </div>
            <div class="mb-5 float-right">
              <mrs-btn-primary buttonLabel="Export Case" buttonId="exportCaseButton"
                (onClick)="exportCase()">
              </mrs-btn-primary>
              </div>
          </form>
          <form #importCaseForm="ngForm">
            <h2 style="padding-top: 25px">Import Case</h2>
            <div class="form-group flex w-100" style="flex-direction: column">
              <div>
                <mat-form-field class="w-100">
                  <mat-select placeholder="Client" [(ngModel)]="importCaseAccountClient" id="importCaseAccount"
                    name="importCaseAccount" #importCaseAccount="ngModel">
                    <mat-option *ngFor="let client of clients" [value]="client">
                      {{client.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex flex-row">
                <input type="file" #file style="display: none" (change)="selectFile()" accept=".json" />
                <span class="btn btn-link btn-sm custom" (click)="onClick()">
                  <a class="text-decoration-none" mat-raised-button color="primary" style="text-transform: uppercase">Select Import File</a>
                </span>
                <span *ngIf="files && files.length > 0">{{files[0].name}}</span>
              </div>
            </div>
            <div class="float-right">
              <mrs-btn-primary buttonLabel="Import Case" buttonId="importCaseButton"
                (onClick)="importCase()">
              </mrs-btn-primary>
              </div>
          </form>
        </mat-card-content>

      </mat-card>
    </div>
  </mat-tab>
  <mat-tab label="Active Case Users">
    <ng-template matTabContent>
      <case-presence></case-presence>
    </ng-template>
  </mat-tab>

  <mat-tab label="Grant Access">
    <div id="innercontent" class="custom-scrollbar flex flex-row" style="flex-wrap: wrap">
      <mat-card appearance="outlined" class="system-card mt-3">
        <mat-card-content>
          <form #grantAccessClientForm="ngForm">
            <div>
              <mat-form-field>
                <mat-select placeholder="Client" [(ngModel)]="grantAccessClientId" id="grantAccessClient"
                  name="grantAccessClient" #grantAccessClient="ngModel">
                  <mat-option *ngFor="let client of clients" [value]="client.id">
                    {{client.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button type="button" (click)="grantAdminAccess()" mat-raised-button color="primary"
            class="float-right mr-2 mb-2 material-default">
            Grant Admin Access
          </button>
          <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
</mat-tab-group>