import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CaseRequirement,
    CaseRequirementResponse,
    Requirement,
    RequirementResponse,
    UWBaseProps,
} from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { CaseRequirementStatuses } from '@Underwriting/types';
import { Utils } from '@Utils';
import { SubSink } from 'subsink';

export type RequirementDropdown = RequirementResponse & {
    disabled: boolean;
    tooltip?: string;
};


@Component({
    selector: 'uw-requirements-add-form',
    templateUrl: './uw-requirements-add-form.component.html',
    styleUrls: ['./uw-requirements-add-form.component.scss']
})
export default class UWRequirementsAddForm implements OnChanges {
    @Input() baseProps: UWBaseProps;
    @Input() caseRequirements: CaseRequirementResponse[];

    characterLimit = 32;
    addCaseRequirementForm: FormGroup;

    tooltip_RuleInUse = 'Limit one';

    // States
    disableAddButton = true;
    showSubtitleField = false; // Show the subtitle text field only the user has picked a Requirement from the dropdown
    hasRequirements = false;

    requirementsResponse: RequirementResponse[] = [];
    requirements: RequirementDropdown[] = [];
    requirementsLoaded = false;

    subs = new SubSink();

    constructor(
        private underWriterWorkbenchService: UWWorkbenchService,
        private uwRequirementsDataService: UWRequirementsDataService,
    ) {
        this.addCaseRequirementForm = new FormBuilder().group({
            requirement: ['', Validators.required],
            subtitle: ['']
        });
    }

    //#region Helpers

    sectionInUseOnCaseRequirement(requirement: RequirementResponse) {
        const sectionInUse = (this.caseRequirements || []).some(cr =>
            !Utils.isNullOrEmptyGuid(cr.section?.id)
            && cr.requirementId.toLowerCase() == requirement.id.toLowerCase()
        );
        return sectionInUse;
    }

    ruleInUseOnCaseRequirement(requirement: RequirementResponse) {
        const ruleInUse = (this.caseRequirements || []).some(cr =>
            !Utils.isNullOrEmptyGuid(cr.rule?.id)
            && cr.requirementId.toLowerCase() == requirement.id.toLowerCase()
        );

        return ruleInUse;
    }

    updateRequirements() {
        this.requirements = this.requirementsResponse.map(r => {
            const requirementDropdown: RequirementDropdown = { ...r, disabled: false };

            // Add reasons to disable adding this Requirement here

            // 1. Requirement with a Section can only be added once
            const requirementHasSection = !Utils.isNullOrEmptyGuid(r.sectionId);
            const disabled_section = requirementHasSection && this.sectionInUseOnCaseRequirement(r);

            // 2. Requirement with a Rule can only be added once
            const requirementHasRule = !Utils.isNullOrEmptyGuid(r.ruleId);
            const disabled_rule = requirementHasRule && this.ruleInUseOnCaseRequirement(r);
            const tooltip = disabled_rule ? this.tooltip_RuleInUse : undefined;

            requirementDropdown.disabled = disabled_rule || disabled_section;
            requirementDropdown.tooltip = tooltip;

            return requirementDropdown;
        });
    }

    updateHasRequirements() {
        this.hasRequirements = this.requirements.length > 0;
    }

    updateDisableAddButton() {
        this.disableAddButton = !this.hasRequirements || !this.showSubtitleField || this.baseProps.readonly;
    }

    clearForm() {
        this.showSubtitleField = false;
        this.addCaseRequirementForm.reset();
        this.addCaseRequirementForm.get('requirement').setErrors(null);
        this.addCaseRequirementForm.get('subtitle').setValue('');
    }

    //#endregion
    //#region Subscriptions

    subscribeToRequirements() {
        const { client, account } = this.baseProps;

        this.subs.add(this.uwRequirementsDataService.loadRequirements(client.code, account.id).subscribe({
            next: requirementResponses => {
                this.requirementsResponse = requirementResponses;
                this.requirementsLoaded = true;
                this.updateRequirements();
                this.updateHasRequirements();
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.subscribeToRequirements();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.caseRequirements) this.updateRequirements();
        this.updateDisableAddButton();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    requirementSelected() {
        const requirementSelected = this.addCaseRequirementForm.get('requirement').value;
        this.showSubtitleField = Boolean(requirementSelected);
        this.updateDisableAddButton();
    }

    handleAddCaseRequirement() {
        if (this.addCaseRequirementForm.get('requirement').hasError('required'))
            return;

        const selectedRequirement: Requirement = this.addCaseRequirementForm.get('requirement').value;
        const addedSubtitle: string = this.addCaseRequirementForm.get('subtitle').value;

        const match = this.caseRequirements.find(x => (x.integration) && x.integration.id == selectedRequirement.integrationId && x.integration.actionId == selectedRequirement.integrationActionId);
        if (match) {
            this.underWriterWorkbenchService
                .updateCaseRequirementMatch(match.id, selectedRequirement.id, selectedRequirement.name, addedSubtitle, this.baseProps.account.id)
                .subscribe(() => {
                    this.clearForm();
                });
        }
        else {
            const addCaseRequirement: CaseRequirement = {
                id: undefined,
                requirementId: selectedRequirement.id,
                name: selectedRequirement.name,
                subtitle: addedSubtitle,
                status: CaseRequirementStatuses.NotOrdered.toString()
            };

            this.underWriterWorkbenchService
                .addCaseRequirement(this.baseProps.account.id, addCaseRequirement, this.baseProps.case.id)
                .subscribe(() => {
                    this.clearForm();
                });
        }

    }

    //#endregion
}
