import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountEvent, AccountMessage } from '../../../../../models';
import { AccountEventsService } from '../../../../../services';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'account-event-action',
    templateUrl: './event-action-email.component.html',
    styleUrls: ['./event-action-email.component.scss']
})
export class EventEmailActionComponent implements OnInit {

    public messageForm: UntypedFormGroup;
    template: any;
    messageId: any;
    formSubmitted = false;
    enableHTMLMode = false;
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '225px',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        sanitize: false,
        toolbarHiddenButtons:[
            ['insertImage',
                'insertVideo',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertHorizontalRule',
                'removeFormat',]
        ]
    };
    public bodyText:string;

    constructor(
        private appService: AppService,
        private accountEventsService: AccountEventsService,
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<EventEmailActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AccountEvent) {}

    ngOnInit() {
        const defaultFromEmailAddress = this.appService.getConfigData('defaultFromEmailAddress');

        this.messageForm = this._fb.group({
            from:[defaultFromEmailAddress, Validators.email],
            to: ['', Validators.required],
            subject: ['', Validators.required],
            content: [''],
            htmlContent: ['', Validators.required],
            textContent:['', Validators.required],
            isHtmlMessage: [false]
        });

        if (this.data.configuration) {
            const configuration = JSON.parse(this.data.configuration);
            this.messageId = configuration['messageId'];

            this.accountEventsService.getAccountEventMessage(this.data.accountId, this.messageId).subscribe(res => {
                this.template = JSON.parse(res.template);

                this.messageForm.patchValue({
                    from:this.template['from'],
                    to: this.template['to'],
                    subject: this.template['subject'],
                    htmlContent: this.template['content'],
                    textContent: this.template['content'],
                    isHtmlMessage:this.template['isHtmlMessage']
                });
            });
        } else if (this.data.accountMessage) {
            this.template = JSON.parse(this.data.accountMessage.template);
            this.messageForm.patchValue({
                from:this.template['from'],
                to: this.template['to'],
                subject: this.template['subject'],
                htmlContent: this.template['content'],
                textContent: this.template['content'],
                isHtmlMessage:this.template['isHtmlMessage']
            });
        }
        this.appendDomainToFrom();
    }

    toggleHtmlMessage(event){
        if(event.checked){
            this.messageForm.get('htmlContent').setValue(this.messageForm.get('textContent').value);
        }else{
            this.messageForm.get('textContent').setValue(this.messageForm.get('htmlContent').value);
        }
    }

    appendDomainToFrom(){
        const currentFrom = this.messageForm.get('from').value;
        if(currentFrom.indexOf('@agenium.ai') < 0)
            this.messageForm.controls['from'].setValue(currentFrom + '@agenium.ai');
    }

    saveAccountMessage() {
        this.appendDomainToFrom();
        const message = this.messageForm.value;
        this.formSubmitted = true;
        let content = '';
        if(this.messageForm.get('isHtmlMessage').value){
            content = this.messageForm.get('htmlContent').value;
            this.messageForm.get('textContent').setValue(this.messageForm.get('htmlContent').value);
        } else {
            content = this.messageForm.get('textContent').value;
            this.messageForm.get('htmlContent').setValue(this.messageForm.get('textContent').value);
        }

        const newMessage = {
            from:message.from,
            to: message.to,
            subject: message.subject,
            content: content,
            isHtmlMessage: message.isHtmlMessage
        };
        if (this.messageForm.valid) {
            if (this.data.accountMessage) {
                this.data.accountMessage.template = JSON.stringify(newMessage);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(newMessage)
                };
                this.data.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.messageForm.markAsTouched();
            this.messageForm.controls.from.markAllAsTouched();
            this.messageForm.controls.to.markAsTouched();
            this.messageForm.controls.subject.markAsTouched();
            this.messageForm.controls.content.markAsTouched();
        }
    }

}
