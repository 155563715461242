import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { ImportExportService, CaseImportExportService, GlobalService, ClientService } from '../services';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-system',
    host: { 'class': 'content' },
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {
  @ViewChild('file') file;
  @ViewChild('file') caseFile;

  files: File[];
  fileData: any;
  caseFiles: File[];
  caseFileData: any;
  accounts: any = [];
  clients: any = [];
  cases: any = [];
  exportAccountClient: any;
  importAccountClient: any;
  importNewAccountCode: any = null;
  importNewAccountName: any = null;
  exportCaseAccountClient: any = {};
  exportCaseModel: any;
  importCaseAccountClient: any;
  grantAccessClientId: any;

  constructor(
    private appService: AppService,
    private globalService: GlobalService,
    private importExportService: ImportExportService,
    private caseImportExportService: CaseImportExportService,
    private clientService: ClientService
  ) {
  }

  ngOnInit() {
      this.appService.getAllConfigData().then((data) => {
          const configData = data;
          this.getAccounts();
          this.getClients();
      });
  }

  checkACL(feature) {
      if (feature === '') { return true; }
      return this.appService.checkACL(feature, 'F');
  }

  flushCache() {
      const url = `System/FlushCache`;
      this.appService.addData(url, null).subscribe(
          data => {
              if (data.status === 'success') {
                  this.appService.showMsg('success', 'Cache Cleared Successfully');
                  this.ngOnInit();
              }
          }
      );
  }

  flushPublicApiCache() {
      this.globalService.flushPublicApiCache().subscribe(
          data => {
              if (data) {
                  this.appService.showMsg('success', 'Cache Cleared Successfully');
                  this.ngOnInit();
              }
          }
      );
  }

  flushFunctionCache() {
      this.globalService.flushFunctionCache().subscribe(
          data => {
              if (data) {
                  this.appService.showMsg('success', 'Cache Cleared Successfully');
                  this.ngOnInit();
              }
          }
      );
  }

  resetShardMap() {
      const url = `System/resetShardMap`;
      forkJoin({
          internalApi: this.appService.addData(url, null),
          publicApi: this.globalService.resetShardMap()
      }).subscribe(result => {
          if (result.internalApi && result.publicApi) {
              this.appService.showMsg('success', 'Shard map reset successfully');
          }
      });

  }

  getAccounts() {
      this.appService.getData('Accounts').subscribe(
          data => {
              const response = data.data;
              if (response && response.length) {
                  this.accounts = _.sortBy(response, [function (o) { return o.clientName; }]);
              }
          }
      );
  }

  getClients() {
      this.appService.getData('Clients').subscribe(
          response => {
              if (response.data && response.data.length) {
                  this.clients = _.sortBy(response.data, [function (o) { return o.name; }]);
              }
          }
      );
  }

  exportConfig() {
      this.importExportService.exportConfig(this.exportAccountClient.clientId, this.exportAccountClient.id);
  }

  onClick() {
      this.file.nativeElement.click();
  }

  onCaseClick() {
      this.caseFile.nativeElement.click();
  }

  selectFile() {
      this.files = [];
      const selectedFiles: { [key: string]: File } = this.file.nativeElement.files;
      for (const key in selectedFiles) {
          if (!isNaN(parseInt(key))) {
              const selectedFile = selectedFiles[key];
              const extension = selectedFiles[0].name.match(/\.([^\.]+)$/)[1];
              if (extension.toLowerCase() === 'json') {
                  var fileReader = new FileReader();
                  fileReader.readAsText(selectedFile, "UTF-8");
                  fileReader.onload = function (evt) {
                      this.fileData = evt.target['result'];
                  }.bind(this);
              } else {
                  this.appService.showMsg('error', 'Please upload json document type only', false);
              }
              this.files.push(selectedFiles[key]);
          }
      }
  }

  selectCaseFile() {
      this.caseFiles = [];
      const selectedFiles: { [key: string]: File } = this.caseFile.nativeElement.files;
      for (const key in selectedFiles) {
          if (!isNaN(parseInt(key))) {
              const selectedFile = selectedFiles[key];
              const extension = selectedFiles[0].name.match(/\.([^\.]+)$/)[1];
              if (extension.toLowerCase() === 'json') {

                  var fileReader = new FileReader();
                  fileReader.readAsText(selectedFile, "UTF-8");
                  fileReader.onload = function (evt) {
                      console.log(JSON.stringify(evt.target['result']));
                  };
                  fileReader.onerror = function (evt) {
                      console.log('error reading file');
                  };

                  const formData: FormData = new FormData();
                  formData.append(key, selectedFile, selectedFile.name);
                  this.caseFileData = formData;
              } else {
                  this.appService.showMsg('error', 'Please upload json document type only', false);
              }
              this.caseFiles.push(selectedFiles[key]);
          }
      }
  }

  importConfig() {
      if (this.importNewAccountCode !== null) {
          this.fileData.append('newAccountCode', this.importNewAccountCode);
      }

      if (this.importNewAccountName !== null) {
          this.fileData.append('newAccountName', this.importNewAccountName);
      }

      this.importExportService.importConfig(this.importAccountClient.id, this.fileData);
  }

  importCase() {
      this.caseImportExportService.importCase(this.importCaseAccountClient.id, this.fileData);
  }

  exportCase() {
      this.caseImportExportService.exportCase(this.exportCaseAccountClient.id, this.exportCaseModel);
  }

  getCaseNumbers() {
      this.caseImportExportService.getCaseNumbersForExport(this.exportCaseAccountClient.clientId, this.exportCaseAccountClient.id).subscribe(
          data => {
              this.cases = data;
          }
      );
  }

  grantAdminAccess(){
      this.globalService.grantAdminAccess(this.grantAccessClientId).subscribe(result => {
          this.appService.showMsg('success', 'Admins have been granted access.  Please clear the cache.');
      });
  }
}
