import { ActionsDTO, RulesDTO, SectionActionsDTO } from "@DTOs";
import { AccountSettings, FeatureToggle, IntegrationEnum, MapKeyType } from '@Enums';
import { Account } from '@Models';
import { AccountSettingsService, ConfirmationDialogService, FeatureManagerService, MapKeyService, NotificationService, NotificationSeverity, RoutesEnum, SectionDataService, SectionService, } from '@Services';
import { InterviewSectionActionService } from '@Services/case/interview-section-action.service';
import { MrsSearchFieldComponent } from "@Shared/components/mrs-search-field/mrs-search-field.component";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from 'app/app.service';
import { MrsDropDownPrimaryItem } from 'app/shared/components/mrs-dropdowns';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { PrefillAnswerComponent } from "@AccountConfig/account-interview/prefill-answer/prefill-answer.component";
import { AccountInterviewService } from './account-interview.service';
import { InterviewNavigationComponent } from './interview-navigation/interview-navigation.component';

export interface ModifiedSectionActionsDTO extends SectionActionsDTO {
    accountBillingId: string;
}
@Component({
    selector: 'account-interview',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-interview.component.html',
    styleUrls: ['./account-interview.component.scss']
})

export class AccountInterviewComponent implements OnInit, OnDestroy {
    @Input() accountInfo: Account;
    @Input() mapKeys;
    @Input() constantListMapKeys;
    @Input() nonConstantMapKeys;
    @Input() variableMapKeys;
    @Input() rules: RulesDTO[];
    @Input() actions: ActionsDTO[];
    @ViewChild(MrsSearchFieldComponent) searchField: MrsSearchFieldComponent;

    sections: any[];
    selectedSection: any;
    selectedQuestion: any;
    selectedReflexiveChoice: any;
    selectedRepeatBlock: any;
    previewQuestions: any[];
    prefillQuestions: any[];
    sectionForm: UntypedFormGroup;
    showDeleteDialog = false;
    message = '';
    sectionActions: ModifiedSectionActionsDTO[];
    expandAllSectionActions: boolean;
    questionActions: any[];
    expandAllQuestionActions: boolean;
    expandAllQuestions: boolean;
    mapKeysList = {};
    filteredVariableMapKeys: any[];
    answerTypes: any;
    answerTypesFiltered: any;
    roles: any;
    questionConfigForm: UntypedFormGroup;
    mapkeyForm: UntypedFormGroup;
    mapKeyIDList = [];
    constantLists: any;
    selectedComponentType: string; // none, section, question, repeat etc.,
    selectedListValues: any = [];
    mapKeyTypeEnum: any = MapKeyType;
    sectionApiEnabled = false;
    enableThirdPartyQuestions = false;
    useThirdPartyQuestions = false;
    thirdPartyIntegrations: Array<any> = [];
    filterValue: string;
    filterableWorkflowToggle = false;
    filterableStaticMapkeysEnabled = false;
    // filteredMapKeys: any = [];
    // isAutoSuggestSelected: boolean = false;
    prefillAnswersSetting = false;

    subscriptions: any = [];
    @Output() onSectionActionAdded = new EventEmitter<any>();
    @Output() onActionsUpdated = new EventEmitter<any>();
    @Output() onHealthQuestionUpdated = new EventEmitter<any>();
    @Output() newMapkeyAdded = new EventEmitter<any>();
    @ViewChildren('sectionAction') sectionActionChildren: QueryList<any>;
    @ViewChildren('questionAction') questionActionChildren: QueryList<any>;
    @ViewChildren(InterviewNavigationComponent) interviewNavigationChildren: QueryList<InterviewNavigationComponent>;
    @ViewChild(PrefillAnswerComponent) prefillComponent: PrefillAnswerComponent;
    popupHtmlEditor: any;
    MCMAReflexivesEnabled = false;
    variantsForListValues = false;

    constructor(
        private appService: AppService,
        private interviewService: AccountInterviewService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        private interviewSectionActionService: InterviewSectionActionService,
        private mapkeyService: MapKeyService,
        private sectionService: SectionService,
        private notificationService: NotificationService,
        private featureManagerService: FeatureManagerService,
        private sectionDataService: SectionDataService,
        private accountSettingsService: AccountSettingsService
    ) {

        this.subscriptions.push(
            this.interviewService.onSectionSelect.subscribe(section => {
                this.selectSection(section);
            })
        );

        this.subscriptions.push(
            this.interviewService.onDeleteSection.subscribe(sectionId => {
                this.deleteSection(sectionId);
            })
        );

        this.subscriptions.push(
            this.interviewService.onQuestionSelect.subscribe(data => {
                this.selectQuestion(data.section, data.question, data.isReflexiveChildren, data.isRepeatBlockChildren, data.repeatBlockParent);
            })
        );

        this.subscriptions.push(
            this.interviewService.onDeleteQuestion.subscribe(questionId => {
                this.deleteQuestion(questionId);
            })
        );

        this.subscriptions.push(
            this.interviewService.onReflexiveChoiceSelect.subscribe(choice => {
                this.selectReflexiveChoice(choice);
            })
        );

        this.subscriptions.push(
            this.interviewService.onGetReflexiveQuestions.subscribe(question => {
                this.getReflexiveQuestions(question);
            })
        );

        this.subscriptions.push(
            this.interviewService.onGetRepeatBlockQuestions.subscribe(question => {
                this.getRepeatBlockQuestions(question);
            })
        );

        this.subscriptions.push(
            this.interviewService.onChangeSectionOrder.subscribe(data => {
                this.changeSectionOrder(data.from, data.to);
            })
        );

        this.subscriptions.push(
            this.interviewService.onChangeQuestionOrder.subscribe(data => {
                this.changeQuestionOrder(data.section, data.from, data.to);
            })
        );

        this.subscriptions.push(
            this.interviewService.onMoveQuestion.subscribe((result: any) => {
                if (result.success) {
                    const question = result.data.data;
                    this.getSectionsAndQuestions(false, question.sectionsId);
                }
            })
        );


        this.selectedComponentType = 'none';
        this.expandAllQuestions = true;

        this.sectionActions = [];
        this.expandAllSectionActions = false;

        this.questionActions = [];
        this.expandAllQuestionActions = false;

        this.answerTypes = appService.getConfigData('answerTypes');
        this.answerTypesFiltered = this.answerTypes.filter(x => x.code !== "MultiChoiceMultiAnswer");

        this.roles = this.appService.getConfigData('userRoles');
        this.mapKeysList = {};
        this.popupHtmlEditor = {
            isVisible: false,
            editorId: '',
            value: ''
        };

    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    ngOnInit() {
        this.MCMAReflexivesEnabled = this.featureManagerService.getByName(FeatureToggle.GlobalMCMAReflexives).enabled;
        this.filterableWorkflowToggle = this.featureManagerService.getByName(FeatureToggle.FilterableWorkflow).enabled;
        this.filterableStaticMapkeysEnabled = this.featureManagerService.getByName(FeatureToggle.FilterableStaticMapkeys).enabled;

        this.subscriptions.push(
            this.accountSettingsService.getAccountSetting(this.accountInfo, AccountSettings.PrefillQuestions).subscribe(result =>{
                this.prefillAnswersSetting = result?.value === 'true';
            })
        );

        this.setMapkeys();

        this.checkACL('R', RoutesEnum.dashboard);
        // this.getSections();
        this.getSectionsAndQuestions();

        // get third party day and process
        this.initThirdPartyIntegrations();

        this.sectionForm = this._fb.group({
            id: '',
            workFlowsID: '',
            accountId: [this.accountInfo.id, Validators.required],
            clientId: [this.accountInfo.clientId, Validators.required],
            name: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
            header: [{ value: '', disabled: !this.checkACL('W') }],
            isMasked: [{ value: false, disabled: !this.checkACL('W') }],
            restartScript: [{ value: '', disabled: !this.checkACL('W') }],
            isProcessingPopupEnabled: [{ value: false, disabled: !this.checkACL('W') }],
            displayMessage: [{ value: false, disabled: true }],
            timeout: [{ value: false, disabled: true }],
            sectionProcessingRuleId: [{ value: null, disabled: true }],
            isSectionHidden: [{ value: false, disabled: !this.checkACL('W') }],
            sectionHiddenRoles: [[], requiredIfValidator(() => this.sectionForm.get('isSectionHidden').value === 'role')],
            sectionHiddenRule: [{
                value: '',
                disabled: !this.checkACL('W')
            }, requiredIfValidator(() => this.sectionForm.get('isSectionHidden').value === 'rule')],
            thirdPartyIntegration: ['']
        });

        this.sectionForm.get('isSectionHidden').valueChanges.subscribe(x => {
            this.sectionForm.get('sectionHiddenRoles').updateValueAndValidity();
            this.sectionForm.get('sectionHiddenRule').updateValueAndValidity();
        });

        this.mapkeyForm = this._fb.group({
            id: '',
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            name: [''],
            parentId: [''],
            mapKeyEntityId: [''],
            rootCategory: '',
            isConstant: false,
            isVirtual: false,
            delimiter: '',
            sanitizedValue: '',
            availableKeys: '',
            virtualMapKeysList: []
        });
    }

    setMapkeys() {
        this.mapKeys = this.mapkeyService.mapKeys;
        this.constantListMapKeys = this.mapkeyService.filterConstantListMapKeysForMultipeChoiceQuestions();
        this.variableMapKeys = this.mapkeyService.variableMapKeys;
        this.nonConstantMapKeys = this.mapkeyService.nonConstantMapKeys;

        this.constantLists = this.constantListMapKeys.reduce((accumulator, current) => {
            accumulator[current.id] = current.constantMapKeyValues;
            return accumulator;
        }, {});
    }

    dropReflex(event) {
        // TECH DEBT: Remove?
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    handleSelectedValueChange(event, listValue, reflexiveQuestion) {
        reflexiveQuestion.refQuestionId = event;
        this.filterReflexiveQuestions(listValue);
    }

    addReflexiveQuestionV2(listValue) {
        listValue.reflexiveQuestions.push({
            'questionId': this.selectedQuestion.id,
            // 'refQuestionId': questionId,
            'answerValueId': listValue.id
        });
        this.filterReflexiveQuestions(listValue);
    }

    removeReflxiveQuestionV2(listValue, qIndex) {
        listValue.reflexiveQuestions.splice(qIndex, 1);
        this.filterReflexiveQuestions(listValue);
    }

    populatePossibleQuestions(listValue) {
        listValue.possibleQuestions = this.getPossibleReflexiveQuestionsV2();
        this.filterReflexiveQuestions(listValue);
    }

    filterReflexiveQuestions(listValue) {
        const reflexives = listValue.reflexiveQuestions;
        reflexives.forEach(reflexive => {
            const excluding = reflexives.filter(x => x.refQuestionId !== reflexive.refQuestionId).map(x => x.refQuestionId);
            reflexive.possibleQuestions = listValue.possibleQuestions.filter(x => !excluding.includes(x.value));
        });
    }

    getPossibleReflexiveQuestionsV2(): MrsDropDownPrimaryItem[] {

        // Find all current possibleReflexiveQuestions for a reflexive item.
        const questionSection = _.find(this.sections, ['id', this.selectedQuestion.sectionsId]);
        if (questionSection && questionSection.questions && questionSection.questions.length) {
            let excludeQuestions: any = [];

            // Exclude Parent Questions
            if (this.selectedQuestion.parentQuestions && this.selectedQuestion.parentQuestions.length) {
                excludeQuestions = [...excludeQuestions, ...this.selectedQuestion.parentQuestions];
            }

            // Exclude current question from possible list of questions
            if (this.selectedQuestion.id) {
                excludeQuestions.push(this.selectedQuestion.id);
            }

            // Get list of possible questions by excluding above filtered questions from section questions.
            // Format title using title of mapkey name
            let possibleQuestions = questionSection.questions.filter(q => !excludeQuestions.includes(q.id));
            let includeQuestions = [];

            this.selectedQuestion.reflexiveQuestionsSource.forEach(refQuestion => {
                if (!excludeQuestions.includes(refQuestion.refQuestionId)) {
                    includeQuestions.push(refQuestion.refQuestionId);
                }
            });

            includeQuestions = questionSection.sectionReflexives.filter(q => includeQuestions.includes(q.id));
            possibleQuestions = [...possibleQuestions, ...includeQuestions];

            possibleQuestions.forEach(q => {
                q.mapKeyName = (q.questionTitle.length ? q.questionTitle : q.mapKeyName);
            });

            possibleQuestions.sort((a, b) => a.mapKeyName.localeCompare(b.mapKeyName));

            const dropdownItems: MrsDropDownPrimaryItem[] = possibleQuestions.map(x => ({ value: x.id, displayValue: x.mapKeyName }));
            return dropdownItems;
        } else {
            return [];
        }
    }

    changeSectionOrder(from, to) {
        this.sections.splice(to, 0, this.sections.splice(from, 1)[0]);
        this.reOrderSections();
    }

    reOrderSections() {
        const sectionsWithOrder = [];
        this.sections.forEach((section, index) => {
            sectionsWithOrder.push({
                id: section.id,
                order: index + 1,
                accountId: this.accountInfo.id,
                clientId: this.accountInfo.clientId
            });
        });
        this.appService.postData('Section/Reorder', sectionsWithOrder, true, false).subscribe(
            data => {
                if (data.status === 'success') {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Order changed...' });
                } else {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                }
            }
        );
    }

    getSectionsAndQuestions(defaultExpanded = true, sectionsId = null) {
        this.appService.getData(`Section/${this.accountInfo.workflowId}`).subscribe(
            data => {
                if (data.status === 'success') {
                    data.data.forEach(section => this.CollectAllMapKeys(section));
                    this.mapKeyIDList.forEach(mapKeyID => this.getListValuesForMapKeyID(mapKeyID));
                    data.data.forEach(section => this.restructureSection(section, defaultExpanded));
                    if (defaultExpanded) {
                        this.sections = data.data;
                    } else {
                        this.sections.forEach(section => {
                            section.questions = data.data.find(x => x.id === section.id).questions;
                            section.sectionReflexives = data.data.find(x => x.id === section.id).sectionReflexives;
                        });


                        if (this.filterValue && this.filterValue.length) {
                            this.interviewNavigationChildren.forEach(child => {
                                child.search();
                            });
                        }
                    }
                }
            }
        );
    }

    CollectAllMapKeys(section) {
        section.questions.forEach(question => this.CollectAllMapKeysForQue(question));
    }

    CollectAllMapKeysForQue(question) {
        if (question.answerType === 'MultiChoiceSingleAnswer' &&
            question.multiChoiceAnswers &&
            question.multiChoiceAnswers[0]) {

            // this.getListValuesToQuestion(question['multiChoiceAnswers'][0]['constantKey'], question);
            if (this.mapKeyIDList.indexOf(question['multiChoiceAnswers'][0]['constantKey']) < -1) {
                this.mapKeyIDList.push(question['multiChoiceAnswers'][0]['constantKey']);
            }

        }

        if ((question.answerType === 'MultiChoiceMultiAnswer' || question.answerType === 'MultiChoiceMultiAnswerV2') &&
            question.multiChoiceAnswers &&
            question.multiChoiceAnswers[0]) {

            // this.getListValuesToQuestion(question['multiChoiceAnswers'][0]['constantKey'], question);
            if (this.mapKeyIDList.indexOf(question['multiChoiceAnswers'][0]['constantKey']) < -1) {
                this.mapKeyIDList.push(question['multiChoiceAnswers'][0]['constantKey']);
            }

        }

    }

    AssignCombosForAllqueinsec(question, mapKeyId) {
        if ((question.answerType === 'MultiChoiceSingleAnswer' || question.answerType === 'MultiChoiceMultiAnswer' || question.answerType === 'MultiChoiceMultiAnswerV2') &&
            question.multiChoiceAnswers &&
            question.multiChoiceAnswers[0] && question['multiChoiceAnswers'][0]['constantKey'] === mapKeyId) {
            question['listValues'] = this.mapKeysList[mapKeyId];
        }
    }

    restructureSection(section, defaultExpanded = true) {
        if (defaultExpanded) {
            section['expanded'] = false;
        }
        section.questions.forEach(question => this.restructureQuestion(question));
    }

    restructureQuestion(question) {
        if (question.propertyValues) {
            question.propertyValues.forEach(property => {
                question[property.name] = property.value;
            });
        }

        question.mapKeyName = (question.questionTitle.length ? question.questionTitle : question.mapKeyName);

        question['reflexiveQuestionsSource'] = JSON.parse(JSON.stringify(question.reflexiveQuestions));
        question['removedReflexiveQuestions'] = [];

        if (question.refQuestionId) { // reflexive question
            question.id = question.refQuestionId;
        }

        question['answerTypeName'] = this.getAnswerTypeName(question.answerType);
    }


    addSection() {
        this.resetSelections();
        this.selectedComponentType = 'section';

        this.sectionForm.reset();
        this.sectionForm.patchValue({
            id: '',
            accountId: this.accountInfo.id,
            workFlowsID: this.accountInfo.workflowId,
            clientId: this.accountInfo.clientId,
            name: '',
            header: '',
            isMasked: false,
            restartScript: '',
            isProcessingPopupEnabled: false,
            displayMessage: '',
            timeout: '',
            sectionProcessingRuleId: null
        });
    }

    buildObjectProperties() {
        const section = this.sectionForm.value;
        section.objectProperties = [{
            key: 'sectionSettings',
            value: JSON.stringify({
                isProcessingPopupEnabled: this.sectionForm.controls['isProcessingPopupEnabled'].value,
                displayMessage: this.sectionForm.controls['displayMessage'].value,
                timeout: this.sectionForm.controls['timeout'].value,
                sectionProcessingRuleId: this.sectionForm.controls['sectionProcessingRuleId'].value
            })
        }];

        section.objectProperties.push({
            key: 'sectionRoleSetting',
            value: JSON.stringify({
                enabled: this.sectionForm.get('isSectionHidden').value !== 'none',
                hiddenForRoleIds: this.sectionForm.get('isSectionHidden').value === 'role' ? this.sectionForm.get('sectionHiddenRoles').value : [],
                hiddenForRuleId: this.sectionForm.get('isSectionHidden').value === 'rule' ? this.sectionForm.get('sectionHiddenRule').value : ''
            })
        });

        return section;
    }

    saveSection() {
        if (this.sectionForm.valid) {
            const section = this.buildObjectProperties();
            this.appService.postData('Section', section, !!this.sectionForm.get('id').value).subscribe(data => {
                if (data.status.toLowerCase() !== 'success') {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                    return;
                }

                this.sectionDataService.reloadSectionsForWorkflow(this.accountInfo.workflowId).subscribe();

                if (this.selectedSection) {
                    this.selectedSection.name = data.data.name;
                    this.selectedSection.header = data.data.header;
                    this.selectedSection.isMasked = data.data.isMasked;
                    this.selectedSection.restartScript = data.data.restartScript;
                    this.selectedSection.objectProperties = section.objectProperties;
                } else {
                    this.restructureSection(data.data);
                    this.sections.push(data.data);
                    this.selectSection(data.data);
                }

                if (this.filterValue && this.filterValue.length) {
                    this.sections.forEach(section => {
                        if (section.id === data.data.id) {
                            section.name = data.data.name;
                            section.header = data.data.header;
                            section.isMasked = data.data.isMasked;
                            section.restartScript = data.data.restartScript;
                            section.objectProperties = data.data.objectProperties;
                        }
                    });
                    this.interviewNavigationChildren.forEach(child => {
                        child.search();
                    });
                }

                this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Your changes have been saved...' });

            });
        }
    }

    resetSelections() {
        this.selectedSection = undefined;
        this.selectedQuestion = undefined;
        this.selectedReflexiveChoice = undefined;
        this.selectedListValues = [];
        this.previewQuestions = [];
    }

    resetSearch() {
        this.resetSelections();
        this.selectedComponentType = 'none';
    }

    selectSection(section) {
        this.resetSelections();
        this.selectedSection = section;
        this.sectionForm.reset();

        const sectionSettings = this.interviewSectionActionService.getSectionSettings(section);
        const sectionHiddenSettings = this.interviewSectionActionService.getSectionRoleHiddenSettings(section);

        let sectionHidden;

        if (sectionHiddenSettings.enabled) {
            sectionHidden = sectionHiddenSettings.hiddenForRoleIds.length == 0 ? 'rule' : 'role';
        } else {
            sectionHidden = 'none';
        }

        this.sectionForm.patchValue({
            id: section.id,
            workFlowsID: section.workFlowsId,
            accountId: section.accountId,
            clientId: section.clientId,
            name: section.name,
            header: section.header,
            isMasked: section.isMasked,
            restartScript: section.restartScript,
            isProcessingPopupEnabled: sectionSettings.isProcessingPopupEnabled,
            displayMessage: sectionSettings.displayMessage,
            timeout: sectionSettings.timeout,
            sectionProcessingRuleId: sectionSettings.sectionProcessingRuleId,
            isSectionHidden: sectionHidden,
            sectionHiddenRoles: sectionHiddenSettings.hiddenForRoleIds,
            sectionHiddenRule: sectionHiddenSettings.hiddenForRuleId,
            thirdPartyIntegration: section.thirdPartyIntegration
        });

        this.onSelectProcessingPopup(sectionSettings.isProcessingPopupEnabled);
        this.selectedComponentType = 'section';
        this.previewQuestions = this.sections.find(x => x.id === section.id).questions;
        this.getSectionActions();
        this.updateSectionApiEnabled(this.selectedSection);

        if (section.thirdPartyIntegration) {
            this.useThirdPartyQuestions = true;
        } else {
            this.useThirdPartyQuestions = false;
        }

    }

    updateApiSettings(apiSetting) {
        if (!this.selectedSection.objectProperties) {
            this.selectedSection.objectProperties = [];
        }

        const sectionApiSettings = this.selectedSection.objectProperties.find(o => o.key === 'sectionApiSettings');
        if (sectionApiSettings) {
            sectionApiSettings.value = JSON.stringify(apiSetting);
        } else {
            this.selectedSection.objectProperties.push({
                key: 'sectionApiSettings',
                value: JSON.stringify(apiSetting)
            });
        }

        this.updateSectionApiEnabled(this.selectedSection);
    }

    updateSectionApiEnabled(section) {
        const sectionApiSettings = this.interviewSectionActionService.getSectionApiSettings(section);
        this.sectionApiEnabled = sectionApiSettings ? sectionApiSettings.enabled : false;

        if (!this.sectionApiEnabled) {
            this.answerTypes = this.appService.getConfigData('answerTypes');
            this.answerTypesFiltered = this.answerTypes.filter(x => x.code !== "MultiChoiceMultiAnswer");
        } else {

            this.answerTypes = _.filter(this.answerTypes, (o) => {
                if (o.code !== 'ActionButton' &&
                    o.code !== 'HealthAnswer' &&
                    o.code !== 'RepeatBlock' &&
                    o.code !== 'UploadFileActionButton') {
                    return o;
                }
            });
            this.answerTypesFiltered = this.answerTypes.filter(x => x.code !== "MultiChoiceMultiAnswer");
        }
    }

    onSelectProcessingPopup(value) {
        if (value === true) {
            this.sectionForm.get('displayMessage').enable();
            this.sectionForm.get('timeout').enable();
            this.sectionForm.get('sectionProcessingRuleId').enable();
        } else {
            this.sectionForm.get('displayMessage').disable();
            this.sectionForm.get('timeout').disable();
            this.sectionForm.get('sectionProcessingRuleId').disable();
        }
    }

    scrollToQuestionOrSectionInNavigation() {
        this.interviewNavigationChildren.forEach(x => x.scrollToQuestionOrSection());
    }

    selectQuestion(section, question, isReflexiveChildren, isRepeatBlockChildren = false, repeatBlockParent = { repeatBlockChildren: [] }) {
        this.resetSelections();

        this.selectedQuestion = question;

        const objectProperties = _.keyBy(question.objectProperties, 'key');
        const prefillProperties = objectProperties['prefillProperties'];
        const preFillSettings = prefillProperties?.value ? JSON.parse(prefillProperties.value) : {};
        if (this.prefillComponent)
            this.prefillComponent.ngOnInit();

        if (this.selectedQuestion.answerType === "MultiChoiceMultiAnswer") {
            this.answerTypesFiltered = this.answerTypes;
        } else {
            this.answerTypesFiltered = this.answerTypes.filter(x => x.code !== "MultiChoiceMultiAnswer");
        }

        if (isReflexiveChildren) {
            // handle reflexive choice selection
            this.selectedComponentType = 'reflexiveQuestion';
            this.selectedSection = {};
            this.previewQuestions = section.reflexiveQuestions;
            this.selectedReflexiveChoice = section;
        } else if (this.selectedQuestion.repeatBlockChildren &&
            this.selectedQuestion.repeatBlockChildren.length > 0) {
            this.selectedComponentType = 'question';
            this.selectedSection = {};
            this.previewQuestions = this.selectedQuestion.repeatBlockChildren;
        } else if (isRepeatBlockChildren) {
            this.selectedQuestion['isRepeatBlockChildren'] = isRepeatBlockChildren;
            this.selectedComponentType = 'question';
            this.selectedSection = {};
            this.selectedRepeatBlock = repeatBlockParent;
            this.previewQuestions = repeatBlockParent.repeatBlockChildren;
        } else {
            this.selectedComponentType = 'question';
            this.selectedSection = section;
            this.previewQuestions = section.questions;
        }
        this.prefillQuestions = this.previewQuestions;
        if (isReflexiveChildren) {
            this.prefillQuestions = this.prefillQuestions.concat(this.sections.find(x => x.id === question.sectionsId).questions);
        }

        this.getQuestionActions();

        let transcriptRuleId = '';
        if (question.questionRules && question.questionRules['transcriptRule']) {
            transcriptRuleId = question.questionRules['transcriptRule'];
        }

        this.questionConfigForm = this._fb.group({
            id: question.id,
            // sectionsId: [section.id, Validators.required],
            sectionsId: [question.sectionsId, Validators.required],
            accountId: [this.accountInfo.id, Validators.required],
            referenceId: [question.referenceId],
            clientId: [this.accountInfo.clientId, Validators.required],
            answerTypeId: [{ value: question.answerTypeId, disabled: !this.checkACL('W') }, Validators.required],
            questionTitle: [{ value: question.questionTitle, disabled: !this.checkACL('W') }, Validators.required],
            mapkeysSearch: '',
            mapkeysId: [{ value: question.mapkeysId, disabled: !this.checkACL('W') }, Validators.required],
            // mapkeysId: question.mapkeysId,
            questionText: [{ value: question.questionText, disabled: !this.checkACL('W') }, Validators.required],
            helpText: { value: question.helpText, disabled: !this.checkACL('W') },
            isRequired: { value: question.isRequired, disabled: !this.checkACL('W') },
            statusId: '00000000-0000-0000-0000-000000000001',
            transcriptRuleId: [transcriptRuleId, ''],
            fromQuestion: preFillSettings.fromQuestion ?? '',
            populateWith: preFillSettings.populateWith ?? '',
            // answerTypeProperties: answerTypeProperties
        });
        if (question.reflexiveQuesionsSource) {
            question.reflexiveQuestionsSource.sort((a, b) => a.order > b.order ? 1 : -1);
            question.reflexiveQuestionsSource.forEach(item => {
                item['questionTitle'] = item['mapKeyName'] = this.getQuestionMapKeyName(item.refQuestionId);
            });
            question['reflexiveQuestions'] = JSON.parse(JSON.stringify(question.reflexiveQuestionsSource));
        }
        this.updateValidationsOnAnswerTypeChange();
        this.addAnswerTypeProperties();
        setTimeout(() => {
            const activeContainer = $('.question-container.active')[0];
            if (activeContainer) {

                $('.center-panel-body').animate({ scrollTop: $('.question-container.active')[0].offsetTop - 50 }, 600);
            }
        }, 0);
    }

    selectReflexiveChoice(choice) {
        this.resetSelections();
        this.selectedReflexiveChoice = choice;
        this.selectedComponentType = 'reflexiveChoice';
        this.previewQuestions = choice.reflexiveQuestions;
    }

    changeQuestionOrder(section, _from, _to) {
        // section.questions.splice(to, 0, section.questions.splice(from, 1)[0]);
        this.reOrderQuestions(section);
    }

    reOrderQuestions(section) {
        const questionsWithOrder = [];
        section.questions.forEach((question, index) => {
            questionsWithOrder.push({
                id: question.id,
                order: index + 1
            });
        });

        const params = {
            questionsList: questionsWithOrder
        };

        this.appService.postData(`Section/${section.id}/QuestionsReorder`, params, true, false).subscribe(
            data => {
                if (data.status === 'success') {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Order changed...' });
                } else {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                }
            }
        );
    }

    deleteQuestion(questionId) {

        this.confirmationService.open({
            message: 'Are you sure that you want to delete this question?',
            onOk: () => {
                this.appService.deleteData(`Questions/${questionId}`).subscribe(
                    data => {
                        if (data.status === 'success') {

                            this.sections.forEach((section, sectionIndex) => {
                                section.questions.forEach((question, questionIndex) => {
                                    if (question.id === questionId) {
                                        this.sections[sectionIndex].questions.splice(questionIndex, 1);
                                    }
                                });
                            });
                            // this.selectedComponentType = 'none';
                            this.closeQuestionProperties();
                            this.onHealthQuestionUpdated.emit();
                            if (this.filterValue && this.filterValue.length) {
                                this.interviewNavigationChildren.forEach(child => {
                                    child.search();
                                });
                            }
                            this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Question Deleted Successfully...' });
                        } else {
                            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                        }
                    }
                );
            },
            showCancel: true
        });

    }

    getSectionActions() {
        this.appService.getData(`Section/${this.selectedSection.id}/Actions`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.sectionActions = data.data;
                }
            }
        );
    }

    getQuestionActions() {
        this.appService.getData(`Questions/${this.selectedQuestion.id}/Actions`).subscribe(
            data => {

                if (data.status === 'success') {
                    this.questionActions = data.data;
                }

            }
        );
    }


    addSectionAction() {

        this.sectionActions = [...this.sectionActions, {
            id: '',
            actionTypeId: '',
            actionsId: '',
            rulesId: '',
            sectionId: this.selectedSection.id,
            accountBillingId: '',
            accountId: this.accountInfo.id
        }] as ModifiedSectionActionsDTO[];

        setTimeout(() => {
            const newAction = this.sectionActionChildren.last;
            newAction.editAction(true);
        }, 0);
    }

    addQuestionAction() {

        this.questionActions = [...this.questionActions, {
            id: '',
            actionTypeId: '',
            actionsId: '',
            rulesId: '',
            questionsId: this.selectedQuestion.id,
            accountBillingId: '',
            accountId: this.accountInfo.id
        }];

        setTimeout(() => {
            const newAction = this.questionActionChildren.last;
            newAction.editAction(true);
        }, 0);
    }

    deleteSectionAction(action, i) {
        if (action.id) {
            this.confirmationService.open({
                message: 'Are you sure that you want to delete this action?',
                onOk: () => {
                    this.appService.deleteData(`Section/${action.id}/Actions`).subscribe(
                        data => {
                            if (data.status === 'success') {
                                this.getSectionActions();
                                this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: data.message });
                                this.onActionsUpdated.emit();
                            }
                        }
                    );
                },
                showCancel: true
            });
        } else {
            this.sectionActions.splice(i, 1);
        }
    }

    deleteQuestionAction(action, i) {
        if (action.id) {
            this.confirmationService.confirm({
                message: 'Are you sure that you want to delete this action?',
                key: 'interviewQuestionActionConfirm',
                accept: () => {
                    this.appService.deleteData(`Questions/${action.id}/Actions`).subscribe(
                        data => {
                            if (data.status === 'success') {
                                this.getQuestionActions();
                                this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: data.message });
                                this.onActionsUpdated.emit();
                                this.questionActions.splice(this.questionActions.indexOf(x => x.id === action.id), 1);
                            }
                        }
                    );
                },
                showCancel: true
            });
        } else {
            this.questionActions.splice(i, 1);
        }
    }

    deleteSection(sectionId) {
        this.appService.getData(`Section/${sectionId}/Dependency`).subscribe(_response => {
            this.confirmationService.confirm({
                message: 'Are you sure that you want to delete this section?',
                key: 'interviewSectionConfirm',
                accept: () => {
                    this.appService.deleteData(`Section/${sectionId}`).subscribe(data => {
                        if (data.status.toLowerCase() !== 'success') {
                            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                            return;
                        }

                        this.sectionDataService.reloadSectionsForWorkflow(this.accountInfo.workflowId).subscribe();

                        this.sections.forEach((section, index) => {
                            if (section.id === sectionId) {
                                this.sections.splice(index, 1);
                            }
                        });

                        this.selectedComponentType = 'none';

                        if (this.filterValue && this.filterValue.length) {
                            this.interviewNavigationChildren.forEach(child => {
                                child.search();
                            });
                        }

                        this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Section Deleted Successfully...' });
                    });
                },
                showCancel: true
            });
        });
    }

    changeDateType(dateType) {
        if (dateType.value === 'priorDays') {
            this.questionConfigForm.patchValue({
                answerTypeProperties: {
                    MinNoOfYears: ''
                }
            });
        }

        if (dateType.value === 'laterDays') {
            this.questionConfigForm.patchValue({
                answerTypeProperties: {
                    MaxNoOfYears: ''
                }
            });
        }
        if (dateType.value === 'priorYears') {
            this.questionConfigForm.patchValue({
                answerTypeProperties: {
                    MinNoOfDays: ''
                }
            });
        }
        if (dateType.value === 'laterYears') {
            this.questionConfigForm.patchValue({
                answerTypeProperties: {
                    MaxNoOfDays: ''
                }
            });
        }
    }

    selectedIsPreFill() {
        return (this.selectedQuestion?.answerType != ''
            && this.selectedQuestion?.answerType != 'RepeatBlock'
            && this.selectedQuestion?.answerType != 'UploadFileActionButton'
            && this.selectedQuestion?.answerType != 'EFTDate'
            && this.selectedQuestion?.answerType != 'HealthAnswer'
            && this.selectedQuestion?.answerType != 'DisplayMessage'
            && this.selectedQuestion?.answerType != 'ActionButton'
            && this.selectedQuestion?.answerType != 'Aura');
    }

    selectAnswerType() {
        const answerTypeId = this.questionConfigForm.get('answerTypeId').value;
        const answerType = _.find(this.answerTypes, ['id', answerTypeId]);

        this.selectedQuestion.answerType = (answerType ? answerType.code : '');
        this.updateValidationsOnAnswerTypeChange();
        this.addAnswerTypeProperties();
        if (!this.selectedIsPreFill() && this.prefillAnswersSetting) {
            if (!this.questionConfigForm.get('fromQuestion')) {
                this.questionConfigForm.addControl('fromQuestion', '');
                this.questionConfigForm.addControl('populateWith', '');
            } else {
                this.questionConfigForm.controls['fromQuestion'].setValue('');
                this.questionConfigForm.controls['populateWith'].setValue('');
            }
        }
    }

    updateValidationsOnAnswerTypeChange() {
        if (this.selectedQuestion.answerType === 'DisplayMessage' || this.selectedQuestion.answerType === 'ActionButton') {
            this.questionConfigForm.get('mapkeysId').clearValidators();
            this.questionConfigForm.get('questionTitle').setValidators(Validators.required);
        } else {
            this.questionConfigForm.get('mapkeysId').setValidators(Validators.required);
            this.questionConfigForm.get('questionTitle').clearValidators();
        }
        this.questionConfigForm.get('mapkeysId').updateValueAndValidity();
    }

    addAnswerTypeProperties() {

        let properties = {};


        switch (this.selectedQuestion.answerType) {
            case 'DisplayMessage':
                properties = {
                    DisplayType: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    MessageType: [{ value: '', disabled: !this.checkACL('W') }, Validators.required]
                };
                break;

            case 'ShortAnswer':
                properties = {
                    Placeholder: [{ value: '', disabled: !this.checkACL('W') }],
                    MinLength: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)],
                    MaxLength: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)],
                    AllowInput: [{ value: 'any', disabled: !this.checkACL('W') }],
                    IsMasked: [{ value: '', disabled: !this.checkACL('W') }],
                    MaskCharacter: [{ value: '', disabled: !this.checkACL('W') }],
                    MaskVisibleCharacters: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)]
                };
                break;

            case 'MultiLineAnswer':
                properties = {
                    Placeholder: [{ value: '', disabled: !this.checkACL('W') }],
                    MinLength: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)],
                    MaxLength: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)]
                };
                break;

            case 'NumericAnswer':
                properties = {
                    Placeholder: [{ value: '', disabled: !this.checkACL('W') }],
                    IsCurrency: [{ value: false, disabled: !this.checkACL('W') }],
                    MinValue: [{ value: '', disabled: !this.checkACL('W') }],
                    MaxValue: [{ value: '', disabled: !this.checkACL('W') }],
                    MaxNumberDecimalPlaces: [{ value: '', disabled: !this.checkACL('W') }],
                    IsMasked: [{ value: '', disabled: !this.checkACL('W') }],
                    MaskCharacter: [{ value: '', disabled: !this.checkACL('W') }],
                    MaskVisibleCharacters: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)]
                };
                break;

            case 'PhoneNumberAnswer':
            case 'RoutingNumberAnswer':
            case 'EmailAnswer':
            case 'SSNAnswer':
                properties = {
                    Placeholder: [{ value: '', disabled: !this.checkACL('W') }]
                };
                break;

            case 'HeightAnswer':
                properties = {
                    MinInches: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)],
                    MaxInches: [{ value: '', disabled: !this.checkACL('W') }, Validators.min(0)]
                };
                break;

            case 'DateAnswer':
                properties = {
                    MinDate: [{ value: '', disabled: !this.checkACL('W') }],
                    MaxDate: [{ value: '', disabled: !this.checkACL('W') }],
                    DateRangeType: [{ value: '', disabled: !this.checkACL('W') }],
                    MinDateType: [{ value: '', disabled: !this.checkACL('W') }],
                    MinNoOfDays: [{ value: '', disabled: !this.checkACL('W') }],
                    MinNoOfYears: [{ value: '', disabled: !this.checkACL('W') }],
                    MaxDateType: [{ value: '', disabled: !this.checkACL('W') }],
                    MaxNoOfDays: [{ value: '', disabled: !this.checkACL('W') }],
                    MaxNoOfYears: [{ value: '', disabled: !this.checkACL('W') }]
                };
                break;

            case 'EFTDate':
                properties = {
                    WednesdayMapkey: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    SpecificdayMapkey: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    MaxAllowedDays: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    MaxAllowedBackDate: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    AllowToday: [{ value: '', disabled: !this.checkACL('W') }, Validators.required]
                };
                break;

            case 'MultiChoiceSingleAnswer':
                properties = {
                    Placeholder: [{ value: 'Please Select', disabled: !this.checkACL('W') }],
                    DisplayType: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    listId: [{ value: '', disabled: !this.checkACL('W') }],
                    indentReflexiveQuestions: [{ value: true, disabled: !this.checkACL('W') }],
                    // reflexiveQuestions: this._fb.array([])
                };

                this.setMapkeys();
                if (this.selectedQuestion['multiChoiceAnswers'][0]) {
                    properties['listId'] = this.selectedQuestion['multiChoiceAnswers'][0]['constantKey'];
                    this.getListValues(properties['listId']);

                    if (this.selectedQuestion.id) {
                        properties['indentReflexiveQuestions'] = [{
                            value: this.selectedQuestion['multiChoiceAnswers'][0]['indentReflexiveQuestions'],
                            disabled: !this.checkACL('W')
                        }];
                    }
                }

                properties['listId'] = [properties['listId'], Validators.required];

                break;

            case 'MultiChoiceMultiAnswer':
            case 'MultiChoiceMultiAnswerV2':
                properties = {
                    Placeholder: [{ value: 'Please Select', disabled: !this.checkACL('W') }],
                    DisplayType: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    listId: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    indentReflexiveQuestions: [{ value: true, disabled: !this.checkACL('W') }],
                    AllowNewValues: false
                };

                if (this.selectedQuestion['multiChoiceAnswers'][0]) {
                    properties['listId'] = this.selectedQuestion['multiChoiceAnswers'][0]['constantKey'];
                    this.getListValues(properties['listId']);

                    if (this.selectedQuestion.id) {
                        properties['indentReflexiveQuestions'] = [{
                            value: this.selectedQuestion['multiChoiceAnswers'][0]['indentReflexiveQuestions'] ?? true,
                            disabled: !this.checkACL('W')
                        }];
                    }
                }

                properties['listId'] = [properties['listId'], Validators.required];

                this.setMapkeys();
                break;

            case 'RepeatBlock':
                properties = {
                    MaxRepeatBlocks: [{ value: '', disabled: !this.checkACL('W') }, [Validators.required, Validators.min(2)]]
                };

                if (this.selectedQuestion['multiChoiceAnswers'][0]) {
                    properties['listId'] = this.selectedQuestion['multiChoiceAnswers'][0]['constantKey'];
                    this.getListValues(properties['listId']);

                    if (this.selectedQuestion.id) {
                        properties['indentReflexiveQuestions'] = this.selectedQuestion['multiChoiceAnswers'][0]['indentReflexiveQuestions'];
                    }
                }
                break;

            case 'ActionButton':
                properties = {
                    ButtonText: [{ value: '', disabled: !this.checkACL('W') }, Validators.required]
                };
                break;
            case 'UploadFileActionButton':
                properties = {
                    ButtonText: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    Tags: [{ value: '', disabled: !this.checkACL('W') }],
                    AllowedFileTypes: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    FileNameFormat: [{ value: '', disabled: !this.checkACL('W') }],
                    UploadStatusMapKey: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
                    IncludeInCasePackage: [{ value: '', disabled: !this.checkACL('W') }],
                };
                break;
        }

        if (this.selectedQuestion.id) {
            this.selectedQuestion.propertyValues.forEach(item => {
                if (Object.keys(properties).includes(item.name)) {
                    if (item.name === 'IsCurrency' || item.name === 'AllowNewValues' || item.name === 'IsMasked' || item.name === 'IncludeInCasePackage' || item.name === 'AllowToday') {
                        properties[item.name] = (item.value === 'true' ? true : false);
                    } else if (item.name === 'Tags' || item.name === 'AllowedFileTypes') {
                        properties[item.name] = [item.value.split(',')];
                    } else {
                        properties[item.name] = item.value;
                    }
                }
            });
        }

        if (this.questionConfigForm.get('answerTypeProperties')) {
            this.questionConfigForm.removeControl('answerTypeProperties');
        }

        this.questionConfigForm.addControl('answerTypeProperties', this._fb.group(properties));

        Object.keys(properties).forEach(prop => {
            this.questionConfigForm.get(`answerTypeProperties.${prop}`).updateValueAndValidity();
            if (!this.checkACL('W')) {
                this.questionConfigForm.get(`answerTypeProperties.${prop}`).disable();
            }
            // console.log(this.questionConfigForm.get(`answerTypeProperties.${prop}`));
        });
    }


    addQuestion() {
        this.selectedComponentType = 'question';
        this.selectedQuestion = {
            answerType: '',
            multiChoiceAnswers: [{
                constantKey: ''
            }],
            reflexiveQuestions: [],
            reflexiveQuestionsSource: [],
            repeatBlockQuestions: [],
            sectionsId: this.selectedSection.id
        };

        const answerTypeProperties = this._fb.group({});

        this.questionConfigForm = this._fb.group({
            id: '',
            sectionsId: [this.selectedSection.id, Validators.required],
            accountId: [this.accountInfo.id, Validators.required],
            clientId: [this.accountInfo.clientId, Validators.required],
            answerTypeId: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
            questionTitle: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
            mapkeysId: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
            mapkeysSearch: [{ value: '', disabled: !this.checkACL('W') }],
            // mapkeysId: '00000000-0000-0000-0000-000000000001',
            questionText: [{ value: '', disabled: !this.checkACL('W') }, Validators.required],
            helpText: [{ value: '', disabled: !this.checkACL('W') }],
            isRequired: [{ value: true, disabled: !this.checkACL('W') }],
            statusId: '00000000-0000-0000-0000-000000000001',
            answerTypeProperties: answerTypeProperties,
            transcriptRuleId: [''],
            referenceId: [null],
            fromQuestion: '',
            populateWith: ''
        });

        this.answerTypesFiltered = this.answerTypes.filter(x => x.code !== "MultiChoiceMultiAnswer");
        // this.subscribeToMapKeySearchEvent();

        this.setMapkeys();

        if (this.filterValue && this.filterValue.length) {
            this.interviewNavigationChildren.forEach(child => {
                child.search();
            });
        }
    }

    saveQuestion() {

        if (this.selectedQuestion.answerType === 'RepeatBlock' &&
            !this.selectedQuestion.repeatBlockQuestions.length) {
            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Add Question to Repeat Block...' });
            return false;
        }

        if (!this.questionConfigForm.valid) {

            return;
        }

        const params = this.questionConfigForm.value;

        if (this.prefillComponent && this.questionConfigForm.controls['fromQuestion']?.value !== null) {
            if (!this.prefillComponent.isFormValid()) return;    //Do not save, need to fix the errors
            params.objectProperties = [{
                key: 'prefillProperties',
                value: JSON.stringify({
                    fromQuestion: this.questionConfigForm.controls['fromQuestion']?.value,
                    populateWith: this.questionConfigForm.controls['populateWith']?.value,
                })
            }];
        }

        let ansTypeProperties = _.find(this.answerTypes, ['id', params.answerTypeId]);
        ansTypeProperties = ansTypeProperties.ansTypeProperties;

        ansTypeProperties.forEach(property => {
            const answerTypePropFormGroup = this.questionConfigForm.controls['answerTypeProperties'] as UntypedFormGroup;
            let prop = answerTypePropFormGroup.controls[property.name];

            if (prop) {
                prop = prop.value?.toString();
            }

            property['value'] = prop;
            property['ansTypePropertiesId'] = property['id'];
        });

        params.propertyValues = ansTypeProperties;

        if (this.selectedQuestion.answerType === 'MultiChoiceSingleAnswer') {
            if (!this.selectedQuestion.multiChoiceAnswers.length) {
                params['multiChoiceAnswers'] = [{
                    constantKey: ''
                }];
            } else {
                params['multiChoiceAnswers'] = this.selectedQuestion.multiChoiceAnswers;
            }
            params.multiChoiceAnswers[0]['constantKey'] = params.answerTypeProperties.listId;
            params.multiChoiceAnswers[0]['indentReflexiveQuestions'] = params.answerTypeProperties.indentReflexiveQuestions;

            const answerValues: any[] = _.uniqBy(this.selectedQuestion.reflexiveQuestions, 'answerValueId');
            if (answerValues) {
                answerValues.forEach(answerValue => {
                    this.orderReflexiveQuestions(answerValue.answerValueId);
                });
            }
            if (this.MCMAReflexivesEnabled) {
                const reflexiveQuestions = [];
                this.selectedListValues.forEach(listItem => {
                    let i = 0;
                    listItem.reflexiveQuestions.forEach(reflexiveQuestion => {
                        reflexiveQuestion.order = i;
                        if (reflexiveQuestion.refQuestionId) {
                            reflexiveQuestions.push(reflexiveQuestion);
                            i++;
                        }
                    });
                });
                params['reflexiveQuestions'] = reflexiveQuestions;
                if (params.reflexiveQuestions.length) {
                    params['isReflexive'] = true;
                }
            } else {
                params['reflexiveQuestions'] = this.selectedQuestion.reflexiveQuestions;
                if (params.reflexiveQuestions.length) {
                    params['isReflexive'] = true;
                }
            }
        }
        if (this.selectedQuestion.answerType === 'MultiChoiceMultiAnswer' || this.selectedQuestion.answerType === 'MultiChoiceMultiAnswerV2') {
            if (!this.selectedQuestion.multiChoiceAnswers.length) {
                params['multiChoiceAnswers'] = [{
                    constantKey: ''
                }];
            } else {
                params['multiChoiceAnswers'] = this.selectedQuestion.multiChoiceAnswers;
            }
            params.multiChoiceAnswers[0]['constantKey'] = params.answerTypeProperties.listId;
            params.multiChoiceAnswers[0]['indentReflexiveQuestions'] = params.answerTypeProperties.indentReflexiveQuestions;

            if (this.MCMAReflexivesEnabled) {
                const reflexiveQuestions = [];
                this.selectedListValues.forEach(listItem => {
                    let i = 0;
                    listItem.reflexiveQuestions.forEach(reflexiveQuestion => {
                        reflexiveQuestion.order = i;
                        if (reflexiveQuestion.refQuestionId) {
                            reflexiveQuestions.push(reflexiveQuestion);
                            i++;
                        }
                    });
                });
                params['reflexiveQuestions'] = reflexiveQuestions;
                if (params.reflexiveQuestions.length) {
                    params['isReflexive'] = true;
                }
            }
        }

        if (this.selectedQuestion.answerType === 'RepeatBlock') {
            this.orderRepeatBlocks();
            params['repeatBlockQuestions'] = this.selectedQuestion.repeatBlockQuestions;
        }

        if (params.transcriptRuleId && params.transcriptRuleId != null && params.transcriptRuleId !== '') {
            params.questionRules = { TranscriptRule: params.transcriptRuleId };
        } else {
            params.questionRules = {};
        }

        // let url = 'Section/' + this.sectionForm.get('id').value;
        this.appService.postData('Questions', params, !!this.questionConfigForm.get('id').value).subscribe(
            data => {
                if (data.status === 'success') {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Question Saved successfully ...' });
                    // this.selectedQuestion = Object.assign(this.selectedQuestion, data.data);
                    this.restructureQuestion(data.data);

                    if (this.questionConfigForm.get('id').value) {

                        if (this.selectedQuestion.isRepeatBlockChildren) {
                            this.selectedQuestion = Object.assign(this.selectedQuestion, data.data);
                        } else {
                            this.selectedQuestion = data.data;
                        }

                        // debugger;
                        if (this.selectedSection.questions) {
                            this.selectedSection.questions.forEach((question, index) => {
                                if (question.id === data.data.id) {
                                    this.selectedSection.questions[index] = data.data;
                                }
                            });
                            this.selectedSection.questions = [...this.selectedSection.questions];
                        } else if (this.selectedReflexiveChoice && this.selectedReflexiveChoice.reflexiveQuestions) {
                            this.selectedReflexiveChoice.reflexiveQuestions.forEach((question, index) => {
                                if (question.id === data.data.id) {
                                    this.selectedReflexiveChoice.reflexiveQuestions[index] = data.data;
                                }
                            });
                        } else if (this.selectedQuestion.answerType === 'RepeatBlock') {
                            const section = _.find(this.sections, ['id', data.data.sectionsId]);

                            if (section) {
                                section.questions.forEach((q, index) => {
                                    if (q.id === data.data.id) {
                                        section.questions[index] = data.data;
                                    }
                                });
                            }
                        }

                        if (this.selectedQuestion.isRepeatBlockChildren) {
                            this.selectedRepeatBlock.repeatBlockChildren.forEach((q, index) => {
                                if (q.id === data.data.id) {
                                    this.selectedRepeatBlock.repeatBlockChildren[index] = data.data;
                                }
                            });
                        }

                    } else {
                        this.selectedSection.questions = this.selectedSection.questions.concat(data.data);
                        this.selectQuestion(this.selectedSection, data.data, false);

                        if (!this.selectedSection.sectionReflexives) {
                            this.selectedSection.sectionReflexives = [];
                        }
                        this.selectedSection.sectionReflexives = this.selectedSection.sectionReflexives.concat(data.data);
                    }

                    data.data.reflexiveQuestions.forEach(rq => {
                        const section = _.find(this.sections, ['id', data.data.sectionsId]);

                        if (section) {
                            section.questions.forEach((q, index) => {
                                if (q.id === rq.refQuestionId) {
                                    section.questions.splice(index, 1);
                                }
                            });
                        }
                    });
                    // trigger the get health questions for configuring mib
                    if (data.data.answerType === 'HealthAnswer') {
                        this.onHealthQuestionUpdated.emit();
                    }
                    // reset top level question list
                    if (data.data.answerType === 'MultiChoiceSingleAnswer' || (data.data.answerType === 'MultiChoiceMultiAnswerV2' && this.MCMAReflexivesEnabled)) {
                        this.appService.getData(`Section/${this.accountInfo.workflowId}`).subscribe(
                            sections => {
                                // let currentSection = _.find(sections.data, ['id', data.data.sectionsId]);
                                // // currentSection
                                // this.selectedSection.sectionReflexives = currentSection.sectionReflexives;

                                // currentSection.questions.forEach(csq => {
                                //     if(!_.find(this.selectedSection.questions, ['id', csq.id])) {
                                //         this.restructureQuestion(csq);
                                //         this.selectedSection.questions = this.selectedSection.questions.concat(csq);
                                //     }
                                // });

                                // this.selectedSection.questions.sort((question1, question2) => {
                                //     return question1.order - question2.order;
                                // })


                                const newSectionData = _.find(sections.data, ['id', data.data.sectionsId]);
                                const oldSectionData = _.find(this.sections, ['id', data.data.sectionsId]);

                                // currentSection
                                oldSectionData.sectionReflexives = newSectionData.sectionReflexives;

                                newSectionData.questions.forEach(csq => {
                                    if (!_.find(oldSectionData.questions, ['id', csq.id])) {
                                        this.restructureQuestion(csq);
                                        oldSectionData.questions = oldSectionData.questions.concat(csq);
                                    }
                                });

                                oldSectionData.questions.sort((question1, question2) => {
                                    return question1.order - question2.order;
                                });

                                if (this.selectedSection && this.selectedSection.questions) {
                                    this.selectedSection.questions.sort((question1, question2) => {
                                        return question1.order - question2.order;
                                    });
                                }
                                if (this.filterValue && this.filterValue.length) {
                                    this.interviewNavigationChildren.forEach(child => {
                                        child.search();
                                    });
                                }
                                // this.previewQuestions = this.selectedSection.questions;
                            }
                        );
                    } else if (data.data.answerType === 'RepeatBlock') {
                        this.appService.getData(`Section/${this.accountInfo.workflowId}`).subscribe(
                            sections => {

                                // this.getRepeatBlockQuestions(this.selectedQuestion);

                                // console.log(this.selectedQuestion);

                                const newSectionData = _.find(sections.data, ['id', data.data.sectionsId]);
                                const oldSectionData = _.find(this.sections, ['id', data.data.sectionsId]);

                                // currentSection
                                oldSectionData.sectionReflexives = newSectionData.sectionReflexives;

                                newSectionData.questions.forEach(csq => {
                                    if (!_.find(oldSectionData.questions, ['id', csq.id])) {
                                        this.restructureQuestion(csq);
                                        oldSectionData.questions = oldSectionData.questions.concat(csq);
                                    }
                                });

                                const oldQuestions = JSON.parse(JSON.stringify(oldSectionData.questions));

                                for (let nindex = 0; nindex <= oldQuestions.length; nindex++) {
                                    oldSectionData.questions.some((oq, oindex) => {
                                        if (!_.find(newSectionData.questions, ['id', oq.id])) {
                                            oldSectionData.questions.splice(oindex, 1);
                                            return true;
                                        }
                                    });
                                }


                                oldSectionData.questions.forEach((csq, index) => {
                                    if (csq.id === this.selectedQuestion.id) {
                                        this.getRepeatBlockQuestions(csq);
                                    }
                                });

                                oldSectionData.questions.sort((question1, question2) => {
                                    return question1.order - question2.order;
                                });

                                if (this.selectedSection && this.selectedSection.question) {
                                    this.selectedSection.questions.sort((question1, question2) => {
                                        return question1.order - question2.order;
                                    });
                                }
                                this.previewQuestions = this.selectedSection.questions;
                                if (this.filterValue && this.filterValue.length) {
                                    this.interviewNavigationChildren.forEach(child => {
                                        child.search();
                                    });
                                }
                            }
                        );
                    } else {
                        this.appService.getData(`Section/${this.accountInfo.workflowId}`).subscribe(
                            sections => {

                                const newSectionData = _.find(sections.data, ['id', data.data.sectionsId]);
                                const oldSectionData = _.find(this.sections, ['id', data.data.sectionsId]);

                                // currentSection
                                oldSectionData.sectionReflexives = newSectionData.sectionReflexives;
                                if (this.filterValue && this.filterValue.length) {
                                    this.interviewNavigationChildren.forEach(child => {
                                        child.search();
                                    });
                                }
                                // newSectionData.questions.forEach(csq => {
                                //     if(!_.find(oldSectionData.questions, ['id', csq.id])) {
                                //         this.restructureQuestion(csq);
                                //         oldSectionData.questions = oldSectionData.questions.concat(csq);
                                //     }
                                // });

                                // oldSectionData.questions.sort((question1, question2) => {
                                //     return question1.order - question2.order;
                                // });

                                // this.selectedSection.questions.sort((question1, question2) => {
                                //     return question1.order - question2.order;
                                // });

                                // this.previewQuestions = this.selectedSection.questions;
                            }
                        );
                    }


                    this.previewQuestions = this.selectedQuestion.isRepeatBlockChildren ? this.selectedRepeatBlock.repeatBlockChildren : this.selectedReflexiveChoice ? this.selectedReflexiveChoice.reflexiveQuestions : this.selectedSection.questions;
                    if (this.filterValue && this.filterValue.length) {
                        this.sections.forEach(section => {
                            if (section.id === this.selectedSection.id) {
                                const foundQuestion = section.questions.find(question => question.id === data.data.id);
                                if (foundQuestion) {
                                    section.questions.forEach(question => {
                                        if (question.id === data.data.id) {
                                            question.mapKeyName = data.data.mapKeyName;
                                            question.AllowInput = data.data.AllowInput;
                                            question.answerType = data.data.answerType;
                                            question.answerTypeId = data.data.answerTypeId;
                                            question.answerTypeName = data.data.answerTypeName;
                                            question.display = data.data.display;
                                            question.mapkeysId = data.data.mapkeysId;
                                            question.propertyValues = data.data.propertyValues;
                                            question.questionActions = data.data.questionActions;
                                            question.questionText = data.data.questionText;
                                            question.questionTitle = data.data.questionTitle;
                                            question.statusId = data.data.statusId;
                                            question.objectProperties = data.data.objectProperties;
                                        }
                                    });
                                } else {
                                    section.questions.push(data.data);
                                }
                            }
                        });
                        this.interviewNavigationChildren.forEach(section => {
                            section.search();
                        });
                    }

                } else {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                }
            }
        );

    }

    getListValues(mapKeyId) {
        if (mapKeyId) {
            this.appService.getData(`MapKeys/${mapKeyId}/MapKey`).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.variantsForListValues = false;
                        const mapkey = this.constantListMapKeys.find(x => x.id === mapKeyId).mapKeyTypeId;
                        if (mapkey !== MapKeyType.DynamicList) {

                            this.selectedListValues = data.data.constantMapKeyValues;
                        } else {
                            this.selectedListValues = [];
                        }
                        this.selectedQuestion['listValues'] = this.selectedListValues;
                        if (this.filterableStaticMapkeysEnabled && data.data.constantMapkeyVariants && data.data.constantMapkeyVariants.length) {
                            this.variantsForListValues = true;
                        }
                        if (this.MCMAReflexivesEnabled && this.selectedQuestion.reflexiveQuestions && this.selectedQuestion.reflexiveQuestions.length) {
                            this.selectedListValues.forEach(listItem => {
                                this.selectedQuestion.reflexiveQuestions.forEach(reflexiveQuestion => {
                                    if (reflexiveQuestion.answerValueId === listItem.id) {
                                        listItem.reflexiveQuestions.push(reflexiveQuestion);
                                    }
                                });
                                listItem.reflexiveQuestions.sort((reflexiveQuestion1, reflexiveQuestion2) => {
                                    return reflexiveQuestion1.order - reflexiveQuestion2.order;
                                });
                            });
                        }
                    } else {
                        this.selectedListValues = [];
                    }
                }
            );
        } else {
            this.selectedListValues = [];
        }
    }


    getListValuesForMapKeyID(mapKeyId) {
        if (mapKeyId) {
            this.appService.getData(`MapKeys/${mapKeyId}/MapKey`).subscribe(
                data => {
                    if (data.status === 'success') {
                        // question['listValues'] = data.data.constantMapKeyValues;
                        this.mapKeysList[mapKeyId] = data.data.constantMapKeyValues;
                    } else {
                        // question['listValues'] = [];
                        this.mapKeysList[mapKeyId] = [];
                    }
                    this.sections.forEach(sec => sec.questions.forEach(que => this.AssignCombosForAllqueinsec(que, mapKeyId)));
                }
            );
        }
    }


    getQuestionMapKeyName(questionId) {
        const questionSection = _.find(this.sections, ['id', this.selectedQuestion.sectionsId]);

        if (questionSection) {
            const q = _.find(questionSection.sectionReflexives, ['id', questionId]);

            if (q) {
                return (q.questionTitle.length ? q.questionTitle : q.mapKeyName);
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    addReflexiveQuestion(questionId, listValueId) {
        const questionReflexives = _.filter(this.selectedQuestion.reflexiveQuestions,
            function (o) {
                return o.answerValueId === listValueId;
            });

        const lastReflexive = questionReflexives[questionReflexives.length - 1];
        let order = 1;
        if (lastReflexive) {
            order = lastReflexive.order + 1;
        }

        this.selectedQuestion.reflexiveQuestions.push({
            'questionId': this.selectedQuestion.id,
            'refQuestionId': questionId,
            'answerValueId': listValueId,
            'order': order
        });
    }


    /**
     * Get list of possible questions which can be associated to
     * a reflexive parent question as children
     *
     * @param listValueId Value of List MapKey Item of a Reflexive Question
     */
    getPossibleReflexiveQuestions(listValueId) {

        // Find current section and get list of questions associated to the section.
        const questionSection = _.find(this.sections, ['id', this.selectedQuestion.sectionsId]);

        if (questionSection && questionSection.questions && questionSection.questions.length) {

            let excludeQuestions: any = [];
            // Get Reflexive Questions associated to a List Item to exclude from possible list of questions
            if (this.selectedQuestion.reflexiveQuestions && this.selectedQuestion.reflexiveQuestions.length > 0) {
                excludeQuestions = this.selectedQuestion.reflexiveQuestions
                    .filter((q: any) => q.answerValueId === listValueId)
                    .map((q: any) => q.refQuestionId);
            }

            // Exclude Parent Questions
            if (this.selectedQuestion.parentQuestions && this.selectedQuestion.parentQuestions.length) {
                excludeQuestions = [...excludeQuestions, ...this.selectedQuestion.parentQuestions];
            }

            // Exclude current question from possible list of questions
            if (this.selectedQuestion.id) {
                excludeQuestions.push(this.selectedQuestion.id);
            }


            // Get list of possible questions by excluding above filtered questions from section questions.
            // Format title using title of mapkey name
            let possibleQuestions = questionSection.questions.filter(q => !excludeQuestions.includes(q.id));

            let includeQuestions = [];

            this.selectedQuestion.reflexiveQuestionsSource.forEach(refQuestion => {
                if (!excludeQuestions.includes(refQuestion.refQuestionId)) {
                    includeQuestions.push(refQuestion.refQuestionId);
                }
            });

            includeQuestions = questionSection.sectionReflexives.filter(q => includeQuestions.includes(q.id));
            possibleQuestions = [...possibleQuestions, ...includeQuestions];

            possibleQuestions.forEach(q => {
                q.mapKeyName = (q.questionTitle.length ? q.questionTitle : q.mapKeyName);
            });

            possibleQuestions.sort((a, b) => a.mapKeyName.localeCompare(b.mapKeyName));

            return possibleQuestions;

        } else {
            return [];
        }
    }

    removeReflexiveQuestion(qIndex) {
        const removedQuestion = this.selectedQuestion.reflexiveQuestions.splice(qIndex, 1);
        this.selectedQuestion.removedReflexiveQuestions = [...this.selectedQuestion.removedReflexiveQuestions, ...removedQuestion];
    }

    getReflexiveQuestions(question) {
        const section = this.sections.find(x => x.questions.some(y => y.id == question.id));
        if (section) {
            section.splice(section.indexOf(x => x.id === question.id), 1, question);
        }
    }

    closeQuestionProperties() {
        this.selectSection(this.selectedSection);
        this.selectedComponentType = 'section';
        this.showDeleteDialog = false;
    }

    getAnswerTypeName(answerTypeCode) {
        const answerType = _.find(this.answerTypes, ['code', answerTypeCode]);

        if (answerType) {
            return answerType.name;
        } else {
            return answerTypeCode;
        }
    }

    // closeSectionForm() {
    //     this.selectedComponentType = 'none';
    //     this.resetSelections();
    // }

    // closeQuestionForm() {
    //     this.selectedComponentType = 'section';
    //     this.selectedQuestion = undefined;
    //     this.selectedReflexiveChoice = undefined;
    // }

    showPopupHTMLEditor(editorId) {
        this.popupHtmlEditor.isVisible = true;
        this.popupHtmlEditor.editorId = editorId;
        switch (editorId) {
            case 'Restart Script':
                this.popupHtmlEditor.value = this.sectionForm.get('restartScript').value;
                break;

            case 'Question Text':
                this.popupHtmlEditor.value = this.questionConfigForm.get('questionText').value;
                break;

            case 'Help Text':
                this.popupHtmlEditor.value = this.questionConfigForm.get('helpText').value;
                break;
        }
    }

    closePopupHtmlEditor() {
        switch (this.popupHtmlEditor.editorId) {
            case 'Restart Script':
                this.sectionForm.get('restartScript').setValue(this.popupHtmlEditor.value);
                break;

            case 'Question Text':
                this.questionConfigForm.get('questionText').setValue(this.popupHtmlEditor.value);
                break;

            case 'Help Text':
                this.questionConfigForm.get('helpText').setValue(this.popupHtmlEditor.value);
                break;
        }
        this.popupHtmlEditor.editorId = '';
        this.popupHtmlEditor.isVisible = false;
    }


    /**
     * Get list of possible questions which can be associated to
     * a Repeat Block as children
     */
    getPossibleRepeatBlockQuestions() {

        // Find current section and get list of questions associated to the section.
        const questionSection = _.find(this.sections, ['id', this.selectedQuestion.sectionsId]);

        if (questionSection) {

            // Get Questions associated to current Repeat Block to exclude from possible list of questions
            let excludeQuestions = [];
            if (this.selectedQuestion.repeatBlockQuestions && this.selectedQuestion.repeatBlockQuestions.length > 0) {
                excludeQuestions = this.selectedQuestion.repeatBlockQuestions.map(q => q.repeatedQuestionId);
            }

            // Exclude Parent Questions
            if (this.selectedQuestion.parentQuestions && this.selectedQuestion.parentQuestions.length) {
                excludeQuestions = [...excludeQuestions, ...this.selectedQuestion.parentQuestions];
            }

            // Exclude current question from possible list of questions
            if (this.selectedQuestion.id) {
                excludeQuestions.push(this.selectedQuestion.id);
            }

            // Exclude MultiChoiceSingleAnswer
            // questionSection.sectionReflexives.forEach(question => {
            //     // if(question.answerType == 'MultiChoiceSingleAnswer') {
            //     if (question.isReflexive) {
            //         excludeQuestions.push(question.id);
            //     }
            // });

            // Get list of possible questions by excluding above filtered questions from section questions.
            // Format title using title of mapkey name
            const possibleQuestions = questionSection.questions.filter(q => !excludeQuestions.includes(q.id));

            possibleQuestions.forEach(q => {
                q.mapKeyName = (q.questionTitle.length ? q.questionTitle : q.mapKeyName);
            });

            return possibleQuestions;

        } else {
            return [];
        }
    }

    addRepeatBlockQuestion(questionId) {
        let idx = 0;

        if (this.selectedQuestion.repeatBlockQuestions) {
            idx = this.selectedQuestion.repeatBlockQuestions.length;
        }

        this.selectedQuestion.repeatBlockQuestions.push({
            // 'id': '',
            'blockQuestionId': this.selectedQuestion.id,
            'repeatedQuestionId': questionId,
            'order': idx + 1
        });
    }

    removeRepeatBlockQuestion(qIndex) {
        this.selectedQuestion.repeatBlockQuestions.splice(qIndex, 1);
    }


    getRepeatBlockQuestions(question) {
        this.appService.getData(`Questions/${question.id}`).subscribe(
            data => {
                if (data.status === 'success') {
                    const repeatBlockChildren = data.data.repeatBlockQuestions;

                    repeatBlockChildren.forEach(repeatBlockQuestion => {
                        this.restructureQuestion(repeatBlockQuestion);
                        repeatBlockQuestion.id = repeatBlockQuestion.repeatedQuestionId;
                        repeatBlockQuestion['isRepeatBlockChildren'] = true;
                    });

                    question['repeatBlockChildren'] = _.sortBy(repeatBlockChildren,
                        function (o) {
                            const repeatBlockQuestion = _.find(question.repeatBlockQuestions, function (r) {
                                return r.repeatedQuestionId === o.id;
                            });
                            return repeatBlockQuestion.order;
                        });
                    // question['onRepeatBloakredOnKey'] = repeatBlockChildren;
                    question['isRepeatBlockQuestionsLoaded'] = true;
                }
            }
        );
    }

    dropSectionAction(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.sectionActions, event.previousIndex, event.currentIndex);

        this.sectionActions.forEach((action, index) => {
            action.order = (index + 1);
        });

        this.appService.postData('Section/Actions/Order', this.sectionActions, true, false).subscribe(response => {
            if (response.status === 'success') {
                this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Order saved successfully ...' });
                this.sectionActions = response.data;
            } else {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: response.message });
            }
        });
    }

    dropQuestionAction(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.questionActions, event.previousIndex, event.currentIndex);

        this.questionActions.forEach((action, index) => {
            action.order = (index + 1);
        });

        this.appService.postData('Questions/Actions/Order', this.questionActions, true, false).subscribe(response => {
            if (response.status === 'success') {
                this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'Order saved successfully ...' });
                this.questionActions = response.data;
            } else {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: response.message });
            }
        });
    }

    dropRepeatBlock(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedQuestion.repeatBlockQuestions, event.previousIndex, event.currentIndex);
        this.orderRepeatBlocks();
    }

    orderRepeatBlocks() {
        this.selectedQuestion.repeatBlockQuestions.forEach((repeatBlock, index) => {
            repeatBlock.order = (index + 1);
        });
    }

    dropReflexiveV2(event: CdkDragDrop<string[]>, listValue) {
        moveItemInArray(listValue.reflexiveQuestions, event.previousIndex, event.currentIndex);
        this.orderReflexiveQuestions(listValue.id);
    }

    dropReflexive(event: CdkDragDrop<string[]>, listValue) {
        moveItemInArray(this.selectedQuestion.reflexiveQuestions, event.previousIndex, event.currentIndex);
        this.orderReflexiveQuestions(listValue.id);
    }

    orderReflexiveQuestions(listValueId) {
        const reflexiveQuestions = _.filter(this.selectedQuestion.reflexiveQuestions, function (o) {
            return o.answerValueId === listValueId;
        });

        reflexiveQuestions.forEach((reflexive, index) => {
            const reflexiveQuestion = _.find(this.selectedQuestion.reflexiveQuestions, function (o) {
                return o.id === reflexive.id;
            });

            reflexiveQuestion.order = (index + 1);
        });
    }

    arrayMove(arr, fromIndex, toIndex) {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    addedQuestionAction() {
        this.getQuestionActions();
        this.onActionsUpdated.emit();
    }

    addedSectionAction() {
        this.getSectionActions();
        this.onActionsUpdated.emit();
    }

    selectFile(event) {
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            return;
        } else {
            // eslint-disable-next-line no-useless-escape
            const extension = selectedFile.name.match(/\.([^\.]+)$/)[1];
            if (extension.toLowerCase() === 'json') {
                const fileReader = new FileReader();
                fileReader.readAsText(selectedFile, 'UTF-8');
                fileReader.onload = () => {
                    const sectionImport = JSON.parse(fileReader.result.toString());
                    this.sectionService.importSection(this.accountInfo.clientId, this.accountInfo.id, this.accountInfo.versionNumber, sectionImport).subscribe(result => {
                        if (this.filterableWorkflowToggle) this.searchField.clearSearchText();
                        this.getSectionsAndQuestions();
                        this.mapkeyService.getMapKeys(this.accountInfo.id, true).subscribe(() => {
                            this.mapKeys = this.mapkeyService.mapKeys;
                            this.constantListMapKeys = this.mapkeyService.constantListMapKeys;
                            this.nonConstantMapKeys = this.mapkeyService.nonConstantMapKeys;

                        });
                    });

                };
            } else {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Please choose a json file type...' });
            }
        }
    }

    onClick(event) {
        event.target.value = null;
    }

    toggleThirdPartyQuestions() {
        if (this.useThirdPartyQuestions) {
            this.useThirdPartyQuestions = false;
            this.sectionForm.get('thirdPartyIntegration').setValue('');
            this.sectionForm.get('thirdPartyIntegration').disable();
        } else {
            this.useThirdPartyQuestions = true;
            this.sectionForm.get('thirdPartyIntegration').enable();
        }
    }

    initThirdPartyIntegrations() {
        this.subscriptions.push(this.sectionService.getThirdPartyIntegrations(this.accountInfo.clientId, this.accountInfo.id)
            .subscribe((value) => {
                const integrations = value?.data.filter(x => x.id === IntegrationEnum.HanoverRe || x.id === IntegrationEnum.Magnum);

                if (Array.isArray(integrations) && integrations.length > 0) {
                    this.thirdPartyIntegrations = integrations;
                    this.enableThirdPartyQuestions = true;
                } else {
                    this.enableThirdPartyQuestions = false;
                }
            })
        );
    }

    collapseAllChildren() {
        this.interviewNavigationChildren.forEach((child) => {
            child.collapseAllChildren();
        });
    }

    fromQuestionSelected($event: string) {
        this.questionConfigForm.get('fromQuestion')?.setValue($event);
        if ($event == '') this.questionConfigForm.get('fromQuestion').disabled;
    }
}


/**
 * makes the field required if the predicate function returns true
 */
function requiredIfValidator(predicate) {
    return (formControl => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return Validators.required(formControl);
        }
        return null;
    });
}
