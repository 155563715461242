<div>
    <!-- NOTE: Failed should be an integrations only state. "manual" requirements can't fail.
        This means there's no manual "Order" or "Edit Subtitle" option -->
    <button mat-menu-item
        *ngIf="caseRequirement.hasIntegration"
        [disabled]="!caseRequirement.canRun"
        (click)="handleMenuAction(UWRequirementTableActions.RunIntegration, caseRequirement)">
        <span *ngIf="props.showRequirementLabel; else runLabel">Run</span>
        <ng-template #runLabel>Reorder</ng-template>
    </button>
    <uw-requirements-actions-item-documents
        [caseRequirement]="caseRequirement"
        (onMenuAction)="handleMenuActionEvent($event)">
    </uw-requirements-actions-item-documents>
    <button mat-menu-item (click)="handleMenuAction(UWRequirementTableActions.FollowUp, caseRequirement)">
        <span *ngIf="!caseRequirement.dateFollowUp; else updateFollowUpTemplate">Add Follow Up</span>
        <ng-template #updateFollowUpTemplate>Edit Follow Up</ng-template>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        *ngIf="caseRequirement.hasIntegration && !props.showRequirementLabel"
        (click)="handleMenuAction(UWRequirementTableActions.Waive, caseRequirement)">
        Waive
    </button>
    <button mat-menu-item
        *ngIf="!props.hideSetCancelledMenuOption"
        (click)="handleMenuAction(UWRequirementTableActions.Cancelled, caseRequirement)">
        Cancel
    </button>    
</div>