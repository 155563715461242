import { MetricEvent, MonitoringService } from "@App/monitor.service";
import { EmbeddedIntegrationData, ThirdPartySectionComponent } from "@Components/third-party-section/third-party-section.component";
import { NavigationFromInterviewData, ThirdPartyNavigationDirection, ThirdPartySectionService } from "@Components/third-party-section/third-party-section.service";
import { FeatureToggle } from "@Enums";
import { FeatureManagerService, MagnumService } from "@Services";
import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { SafeResourceUrl } from "@angular/platform-browser";
import { AppService } from "app/app.service";
import { Subscription } from "rxjs";

export interface MagnumEmbeddedIntegrationResult {
    caseUUID: string;
    securitySessionToken: string;
    endpointAPI: string;
    isSuccess: boolean;
}

@Component({
    selector: 'magnum-component',
    host: { 'class': 'content dialog-iframe' },
    templateUrl: './magnum.component.html',
    styleUrls: ['./magnum.component.scss']
})
export class MagnumComponent implements ThirdPartySectionComponent, OnDestroy, AfterViewInit {
    securitySessionToken: string;
    endpointAPI: string;
    caseUUID: string;
    url: SafeResourceUrl;
    showErrorMessage = false;

    private _embeddedData: EmbeddedIntegrationData;
    private _magnumInterviewComponent: any;
    private _magnumInterviewComponentsWrapper: any;
    private attemptingToGoBack = false;
    private magnumOverlayShowMetric: MetricEvent;

    readonly CASE_SUMMARY_MESSAGE = 'Unable to view SwissRe: Magnum third party section in Case Summary.';
    readonly INTEGRATION_ERROR_MESSAGE = 'Error loading page.  Please refresh your browser.  If you continue to get this error please contact Support.';
    private navigationFromInterviewSub: Subscription;
    private _globalMagnumListenToCaseResumedEvent: boolean = false;
    private _globalMagnumLogFrontEndDetails: boolean = false;
    private _caseResumedEventRecieved: boolean = false;
    private _loadAttempts: number = 0;


    get magnumInterviewComponent() {
        if (!this._magnumInterviewComponent)
            this._magnumInterviewComponent = document.querySelector('magnum-interview');
        return this._magnumInterviewComponent;
    }

    get embeddedData() {
        return this._embeddedData;
    }

    @Input() set embeddedData(value) {
        this._embeddedData = value;
        const payload: MagnumEmbeddedIntegrationResult = JSON.parse(this.embeddedData.payloadData);
        this.showErrorMessage = !payload.isSuccess;
        if (payload.isSuccess) {
            this.securitySessionToken = payload.securitySessionToken;
            this.endpointAPI = payload.endpointAPI;
            this.caseUUID = payload.caseUUID;
        } else {
            this.appService.display(false);
        }
    }

    constructor(
        private renderer: Renderer2,
        private thirdPartySectionService: ThirdPartySectionService,
        @Inject(DOCUMENT) private document: Document,
        private appService: AppService,
        private featureManagerService: FeatureManagerService,
        private monitoringService: MonitoringService,
        private magnumService: MagnumService
    ) {
        this._loadAttempts = 0;
        this._globalMagnumListenToCaseResumedEvent = this.featureManagerService.getByName(FeatureToggle.GlobalMagnumListenToCaseResumedEvent).enabled
        this._globalMagnumLogFrontEndDetails = this.featureManagerService.getByName(FeatureToggle.GlobalMagnumLogFrontEndDetails).enabled
        if (this._globalMagnumListenToCaseResumedEvent) {
            const observer = new MutationObserver((mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                        const interviewComp = document.querySelector('magnum-interview');
                        if (interviewComp) {
                            interviewComp.setAttribute('no-overlay', '');
                            observer.disconnect();
                        }
                    }
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });
        }
    }

    ngOnDestroy(): void {

        if (this.navigationFromInterviewSub) this.navigationFromInterviewSub.unsubscribe();
        if (!this.embeddedData.isCaseSummary) this.removeMagnumEventListeners();
    }

    private getDefaultEventProperties(): { [key: string]: string } {

        const result = {};

        if (this.embeddedData) {
            result['payloadData'] = this.embeddedData.payloadData;
            result['caseUUID'] = this.caseUUID;
        }

        return result;
    }

    ngAfterViewInit() {
        let metricEvent;
        if (this._globalMagnumLogFrontEndDetails) {
            metricEvent = this.monitoringService.beginMetric('MagnumInit', this.getDefaultEventProperties());
        }

        //Only load Magnum if we are not in Case Summary
        if (!this.embeddedData.isCaseSummary) {
            this.navigationFromInterviewSub = this.thirdPartySectionService.navigationFromInterviewObservable.subscribe((data: NavigationFromInterviewData) => {
                if (data.userClickedNext) this.magnumNext();
                if (data.userClickedPrevious) this.magnumPrevious();
            });

            if (this.showErrorMessage) {
                return;
            }
            this.loadScripts();
        }

        if (this._globalMagnumLogFrontEndDetails && metricEvent) {
            this.monitoringService.endMetric(metricEvent);
            this.monitoringService.flushMetric(metricEvent.name);
        }
    }

    magnumNext() {
        if (this.magnumInterviewComponent)
            this.magnumInterviewComponent.continue();
    }

    magnumPrevious() {
        this.magnumSave();
        this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Previous);
        this.appService.display(false);
        // if (this.showErrorMessage) this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Previous);
        // if (this.magnumInterviewComponent) {
        //     this.magnumInterviewComponent.back();
        //     this.attemptingToGoBack = true;
        // }
    }

    magnumSave() {
        if (this.magnumInterviewComponent) {
            this.magnumInterviewComponent.save();
        }
    }


    /* istanbul ignore next */
    private loadScripts() {
        let metricEvent;
        if (this._globalMagnumLogFrontEndDetails) {
            metricEvent = this.monitoringService.beginMetric('MagnumLoadScripts', this.getDefaultEventProperties());
        }
        const securityUrl = `${this.endpointAPI}/cdn/magnum-cloud-security.min.js`;
        const magnumRul = `${this.endpointAPI}/cdn/magnum.min.js`;
        const magnumCssUrl = `${this.endpointAPI}/cdn/magnum.min.css`;
        try {
            if (!document.querySelector(`script[src="${securityUrl}"]`)) {
                const cloudSecurityScript = this.renderer.createElement('script');
                cloudSecurityScript.src = securityUrl;
                this.renderer.appendChild(this.document.body, cloudSecurityScript);
            }

            if (!document.querySelector(`script[src="${magnumRul}"]`)) {
                const magnumScript = this.renderer.createElement('script');
                magnumScript.src = magnumRul;
                this.renderer.appendChild(this.document.body, magnumScript);
            }

            if (!document.querySelector(`link[href="${magnumCssUrl}"]`)) {
                const styleSheet = this.renderer.createElement('link');
                styleSheet.rel = 'stylesheet';
                styleSheet.href = magnumCssUrl;
                this.renderer.appendChild(this.document.body, styleSheet);
            }
            this.loadMagnumInterview();
        } catch (e) {

            if (this._globalMagnumLogFrontEndDetails) {
                const props = this.getDefaultEventProperties();
                props['attempts'] = this._loadAttempts.toString();
                props['thirdParty'] = 'Magnum';
                this.monitoringService.logError(e, props);
            }
            if (this._loadAttempts < 5 && this._globalMagnumListenToCaseResumedEvent) {
                setTimeout(() => {
                    this._loadAttempts++;
                    this.loadScripts();
                }, 100);
            } else {
                this.showErrorMessage = true;
                this.appService.display(false);
            }
        }
        if (this._globalMagnumLogFrontEndDetails && metricEvent) {
            this.monitoringService.endMetric(metricEvent);
            this.monitoringService.flushMetric(metricEvent.name);
        }
    }

    private refreshToken() {
        this.magnumService.refreshToken(this.caseUUID).subscribe({
            next: (result: EmbeddedIntegrationData) => {
                this.embeddedData = result;
                this._loadAttempts++;
                this.loadScripts();
            },
            error: (error) => {
                this.showErrorMessage = true;
                this.appService.display(false);
                console.error(error);
            }
        });
    }

    private loadMagnumInterview() {

        let metricEvent;

        if (this._globalMagnumLogFrontEndDetails) {
            metricEvent = this.monitoringService.beginMetric('MagnumCaseResumed', this.getDefaultEventProperties());
        }
        const apiEndpoint = this.endpointAPI + '/engine/rest/v1';

        //Create the Magnum Interview Component Wrapper
        this._magnumInterviewComponentsWrapper = document.querySelector('magnum-icw');
        this._magnumInterviewComponentsWrapper.addEventListener('magnum-application-error', function (event) {
            this.monitoringService.endMetric(metricEvent);
            this.refreshToken();
        }.bind(this));
        this._magnumInterviewComponentsWrapper.setAttribute('base-url', apiEndpoint);
        this._magnumInterviewComponentsWrapper.setAttribute('security-session-token', this.securitySessionToken);
        this._magnumInterviewComponentsWrapper.setAttribute('case-id', this.caseUUID);
        this._magnumInterviewComponentsWrapper.setAttribute('menu-mode', 'hidden');
        this._magnumInterviewComponentsWrapper.setAttribute('no-summary-consent', '');
        this._magnumInterviewComponentsWrapper.setAttribute('no-summary', '');
        this._magnumInterviewComponentsWrapper.setAttribute('disable-save-popup', true);
        this._magnumInterviewComponentsWrapper.setAttribute('no-overlay', '');

        //Listen for events from Magnum Interview Component Wrapper
        this._magnumInterviewComponentsWrapper.addEventListener('magnum-interview-completed', this.magnumInterviewCompletedEventListener.bind(this));
        this._magnumInterviewComponentsWrapper.addEventListener('magnum-interview-cancelled', this.magnumInterviewCancelledEventListener.bind(this));
        this._magnumInterviewComponentsWrapper.addEventListener('magnum-application-error-confirmed', this.magnumApplicationErrorConfirmedEventListener.bind(this));

        if (this._globalMagnumListenToCaseResumedEvent) {
            //Add this to the case resumed event listener
            this._magnumInterviewComponentsWrapper.addEventListener('case-resumed', function (event) {
                this._caseResumedEventRecieved = true;
                this._magnumInterviewComponent = document.querySelector('magnum-interview');
                this._magnumInterviewComponent.setAttribute('no-overlay', '');
                this._magnumInterviewComponent.setAttribute('disable-save-popup', true)

                this._magnumInterviewComponent.setAttribute('menu-mode', 'hidden');
                this._magnumInterviewComponent.setAttribute('no-back-button', true);
                this._magnumInterviewComponent.setAttribute('no-save-button', true);
                this._magnumInterviewComponent.setAttribute('no-continue-button', true);
                //Listen to IDC Events
                this._magnumInterviewComponent.addEventListener('magnum-overlay-show', this.magnumOverlayShowEventListener.bind(this));
                this._magnumInterviewComponent.addEventListener('magnum-overlay-hide', this.magnumOverlayHideEventListener.bind(this));
                this._magnumInterviewComponent.addEventListener('case-check-invalid', this.magnumCaseCheckInvalidEventListener.bind(this));

                const magnumForms = document.querySelectorAll('magnum-form');
                if (magnumForms.length > 0) {
                    magnumForms.forEach((form: Element) => {
                        form.setAttribute('errors-appearance', "belowAnswer");
                    });
                }
                this.appService.display(false);
                if (this._globalMagnumLogFrontEndDetails && metricEvent) {
                    this.monitoringService.endMetric(metricEvent);
                    this.monitoringService.flushMetric(metricEvent.name);
                }
            }.bind(this));

            if (this._globalMagnumLogFrontEndDetails && metricEvent) {
                setTimeout(function () {
                    if (!this._caseResumedEventRecieved) {
                        this.monitoringService.endMetric(metricEvent);
                        this.monitoringService.flushMetric(metricEvent.name);
                        const props = this.getDefaultEventProperties();
                        props['timeout'] = `60000`;
                        props['thirdParty'] = 'Magnum';
                        this.monitoringService.logError(new Error('Magnum Timeout'), props);
                        this.showErrorMessage = true;
                        this.appService.display(false);
                    }
                }.bind(this), 60000);
            }
        } else {
            //Start polling for the interview page
            this.getMagnumInterviewComponentAsync().then((magnumInterviewComponent: Element) => {
                //Set IDC Attributes
                this._magnumInterviewComponent = magnumInterviewComponent;
                this._magnumInterviewComponent.setAttribute('menu-mode', 'hidden');
                this._magnumInterviewComponent.setAttribute('no-back-button', true);
                this._magnumInterviewComponent.setAttribute('no-save-button', true);
                this._magnumInterviewComponent.setAttribute('no-continue-button', true);
                this._magnumInterviewComponent.setAttribute('no-overlay', '');
                this._magnumInterviewComponent.setAttribute('disable-save-popup', true)

                //Listen to IDC Events
                this._magnumInterviewComponent.addEventListener('magnum-overlay-show', this.magnumOverlayShowEventListener.bind(this));
                this._magnumInterviewComponent.addEventListener('magnum-overlay-hide', this.magnumOverlayHideEventListener.bind(this));
                this._magnumInterviewComponent.addEventListener('case-check-invalid', this.magnumCaseCheckInvalidEventListener.bind(this));

            });

            //Start polling for the forms
            this.getMagnumFormsAsync().then((magnumForms: Element[]) => {
                magnumForms.forEach((form: Element) => {
                    form.setAttribute('errors-appearance', "belowAnswer");
                });
                if (this._globalMagnumLogFrontEndDetails && metricEvent) {
                    this.monitoringService.endMetric(metricEvent);
                    this.monitoringService.flushMetric(metricEvent.name);
                }
            });
        }
    }

    private magnumInterviewCancelledEventListener() {
        if (this.attemptingToGoBack) {
            this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Previous);
        }
    }

    private magnumInterviewCompletedEventListener() {
        this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Next);
    }

    private magnumOverlayShowEventListener() {
        this.appService.display(true);
        if (this._globalMagnumLogFrontEndDetails) {
            this.magnumOverlayShowMetric = this.monitoringService.beginMetric('MagnumShowOverlay', this.getDefaultEventProperties());
        }
    }

    private magnumOverlayHideEventListener() {
        this.appService.display(false);
        if (this._globalMagnumLogFrontEndDetails && this.magnumOverlayShowMetric) {
            this.monitoringService.endMetric(this.magnumOverlayShowMetric);
        }
    }

    private magnumCaseCheckInvalidEventListener() {
        if (this.attemptingToGoBack) {
            this.attemptingToGoBack = false;
            this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Previous);
        }
    }

    private magnumApplicationErrorConfirmedEventListener() {
        this.showErrorMessage = true;
        this.appService.display(false);
    }

    // Keep trying to find the magnum-interview element on the page until it is found asynchonously
    // Throw an error message after trying 10 times
    private async getMagnumInterviewComponentAsync() {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                const magnumInterviewComponent = document.querySelector('magnum-interview');
                if (magnumInterviewComponent) {
                    clearInterval(interval);
                    resolve(magnumInterviewComponent);
                }
            }, 100);
        });
    }

    // Keep trying to find the magnum-interview element on the page until it is found asynchonously
    private async getMagnumFormsAsync() {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                const magnumForms = document.querySelectorAll('magnum-form');
                if (magnumForms.length > 0) {
                    clearInterval(interval);
                    resolve(magnumForms);
                }
            }, 100);
        });
    }

    private removeMagnumEventListeners() {
        // //Unlisten to IDC Events
        this._magnumInterviewComponent?.removeEventListener('magnum-overlay-show', this.magnumOverlayShowEventListener.bind(this));
        this._magnumInterviewComponent?.removeEventListener('magnum-overlay-hide', this.magnumOverlayHideEventListener.bind(this));
        this._magnumInterviewComponent?.removeEventListener('case-check-invalid', this.magnumCaseCheckInvalidEventListener.bind(this));
        //
        // //Unlisten to ICW Events
        this._magnumInterviewComponentsWrapper?.addEventListener('magnum-interview-completed', this.magnumInterviewCompletedEventListener.bind(this));
        this._magnumInterviewComponentsWrapper?.addEventListener('magnum-interview-cancelled', this.magnumInterviewCancelledEventListener.bind(this));
        this._magnumInterviewComponentsWrapper?.addEventListener('magnum-application-error-confirmed', this.magnumApplicationErrorConfirmedEventListener.bind(this));
        if (this._globalMagnumLogFrontEndDetails) {
            this.monitoringService.flushMetrics();
        }
    }
}
