<h1 mat-dialog-title>Configure Email</h1>
  <div mat-dialog-content>
    <div class="flex-fill d-flex flex-column overflow-y-auto">
      <form [formGroup]="messageForm" novalidate autocomplete="off" >
        <mat-form-field class="w-100">
          <mat-label>From</mat-label>
          <input id="from" type="email" matInput formControlName="from" (change)="appendDomainToFrom()">
           <mat-error *ngIf="messageForm.get('from').hasError('email')">
            Please enter a valid email address (name@agenium.ai)
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>To</mat-label>
          <input matInput formControlName="to">
          <mat-error *ngIf="formSubmitted && messageForm.get('to').hasError('required')">
            To
            <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="messageForm.get('to').hasError('email')">
           Please enter a valid email address (name@example.com)
         </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Subject</mat-label>
          <input matInput formControlName="subject">
          <mat-error *ngIf="formSubmitted && messageForm.get('subject').hasError('required')">
            Subject
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle [id]="'slide-toggle'" formControlName="isHtmlMessage" (change)="toggleHtmlMessage($event)" class="mr-2">Send as HTML Email</mat-slide-toggle>
        <angular-editor id="html-input" *ngIf="messageForm.get('isHtmlMessage').value" matInput [config]="editorConfig" formControlName="htmlContent"  placeholder="Body"></angular-editor>
        <mat-form-field *ngIf="!messageForm.get('isHtmlMessage').value" class="w-100 textarea-formfield">
          <textarea id="text-input" matInput formControlName="textContent" rows=10></textarea>
          <mat-error *ngIf="formSubmitted &&(messageForm.get('textContent').hasError('required')||messageForm.get('htmlContent').hasError('required'))">
            Body
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close >
      CANCEL
    </button>
    <button id="okButton" mat-button color="primary" class="mr-1 material-default" (click)="saveAccountMessage()">
      OK
    </button>
  </mat-dialog-actions>
