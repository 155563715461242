<div class="right-panel flex-fill d-flex flex-column">
    <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3" style="background-color: #e6e6e6;">
        <div class="row">
            <div class="col-6 mx-auto">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title-group>
                            <mat-card-title>
                                <h2>Events</h2>
                            </mat-card-title>
                            <mrs-btn-icon class="float-right" buttonLabel="Add" buttonId="addButton" (onClick)="addAccountEvent()" iconName="add"></mrs-btn-icon>
                        </mat-card-title-group>
                    </mat-card-header>
                    <mat-card *ngFor="let event of events" class="mt-3" appearance="outlined" [ngClass]="{'event-card': selectedEvent != event}">
                        <mat-card-content>
                            <div class="flex flex-row" (click)="selectEvent(event)" id="event-card">
                                <mat-slide-toggle [checked]="event.isActive" (change)="eventActiveToggled(event)" class="mr-2">
                                </mat-slide-toggle>
                                <span>{{ event.name }}</span>
                                <div class="flex-1"></div>
                                <div style="justify-content: flex-end">
                                    <i aria-hidden="true" class="fa fa-trash" (click)="deleteAccountEvent(event)" *ngIf="event.id != null"
                                        id="delete-event"></i>
                                </div>
                            </div>
                            <div *ngIf="selectedEvent === event">
                                <form class="px-5 py-3" [formGroup]="eventForm" (ngSubmit)="saveAccountEvent()" novalidate autocomplete="off">

                                    <mat-form-field class="w-100">
                                        <mat-label>Name</mat-label>
                                        <input matInput formControlName='name' id="event-name">
                                        <mat-error *ngIf="formSubmitted && eventForm.get('name').hasError('required')">Name <strong>required</strong></mat-error>
                                    </mat-form-field>

                                    <auto-suggest
                                        id="system-event"
                                        field="name"
                                        dataKey="id"
                                        formControlName="systemEventId"
                                        placeholder="System Event"
                                        [list]="(enableCaseStatusV2 || event.systemEventId === SystemEventEnum.CaseStatusV2) ? eventTypes : eventTypesSansCaseStatusV2"
                                        [showRequiredError]="formSubmitted && eventForm.get('systemEventId').hasError('required')">
                                    </auto-suggest>

                                    <auto-suggest *ngIf="eventConditions && eventConditions.length > 0"
                                        id="system-event-condition"
                                        field="name"
                                        dataKey="id"
                                        formControlName="conditionId"
                                        allowNewValues=false
                                        [list]="eventConditions"
                                        [placeholder]="eventConditionLabel"
                                        [multiple]="multipleEventConditions"
                                        [showRequiredError]="formSubmitted && eventForm.get('conditionId').hasError('required')">
                                    </auto-suggest>

                                    <div class="flex flex-row">
                                        <auto-suggest style="flex: 1" [list]="filteredActions" placeholder="Action" field="name"
                                            dataKey="id" formControlName="systemEventActionId" id="system-event-action"
                                            [showRequiredError]="formSubmitted && eventForm.get('systemEventActionId').hasError('required')">
                                        </auto-suggest>
                                        <i aria-hidden="true"
                                            class="fa fa-pencil-alt m-2"
                                            (click)="editAction(event)"
                                            *ngIf=" eventForm.get('systemEventActionId').value != null
                                                && eventForm.get('systemEventActionId').value != ''
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000004'
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000008'
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000010'
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000011'
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000013'
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000014'
                                                && eventForm.get('systemEventActionId').value != '00000000-0000-0000-0000-000000000018'"
                                            style="margin-right: -24px !important; cursor: pointer; padding-top:6px;"></i>
                                    </div>
                                    <auto-suggest
                                        *ngIf="actionConditions
                                            && actionConditions.length > 0
                                            && eventForm.get('systemEventActionId').value != null
                                            && eventForm.get('systemEventActionId').value != ''
                                            && eventForm.get('systemEventActionId').value == '00000000-0000-0000-0000-000000000010'"
                                        [list]="actionConditions" [placeholder]="actionConditionLabel" field="name" dataKey="id"
                                        formControlName="actionConditionId" id="system-action-condition"
                                        [showRequiredError]="formSubmitted && eventForm.get('actionConditionId').hasError('required')">
                                    </auto-suggest>
                                    <div class="flex flex-row" *ngIf="toggleConditions">
                                        <mat-slide-toggle id="toggleCondition" [formControlName]="this.toggleConditions.name">
                                            {{toggleConditionLabel}}</mat-slide-toggle>
                                    </div>
                                    <auto-suggest [list]="rules" placeholder="Rule" field="name" dataKey="id" formControlName="ruleId"
                                        id="system-event-rule">
                                    </auto-suggest>
                                    <div class="pt-4 flex" style="justify-content: flex-end;align-items: center;">
                                        <mrs-btn-tertiary buttonLabel=" Cancel" buttonId="cancelBtn" (onClick)="cancelEvent(event)">
                                        </mrs-btn-tertiary>
                                        <mrs-btn-primary buttonLabel="Save" buttonId="saveBtn" (onClick)="submit" type="submit"
                                            id="system-event-save">
                                        </mrs-btn-primary>
                                    </div>
                                </form>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </mat-card>
            </div>
        </div>
    </div>
</div>