<div class="left-panel d-flex flex-column bg-light">
    <div style="box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25); z-index:1; padding-left:24px" class="flex-grow-0">
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="headingPadding">Workflow</h2>
            <span style="margin-right:12px">
                <button (click)="collapseAllChildren();" *ngIf="checkACL('W')" mat-icon-button
                    matTooltip="collapse all">
                    <mat-icon svgIcon="arrow-collapse-vertical"></mat-icon>
                </button>
                <input #fileInput (change)="selectFile($event)" (click)="onClick($event)" accept=".json" class="d-none"
                    hidden="true" type="file" />
                <button (click)="fileInput.click()" *ngIf="checkACL('W')" mat-icon-button matTooltip="Import Section">
                    <mat-icon>upload</mat-icon>
                </button>
                <button (click)="addSection()" *ngIf="checkACL('W')" aria-label="Add Section" mat-icon-button
                    matTooltip="Add Section">
                    <mat-icon>add</mat-icon>
                </button>
            </span>
        </div>
        <div style="padding-right:24px" *ngIf="filterableWorkflowToggle">
            <mrs-search-field [placeholder]="'Search sections and questions'"
                [(searchText)]="filterValue"></mrs-search-field>
        </div>
    </div>
    <div cdkScrollable class="left-panel-body flex-fill overflow-y-auto">
        <interview-navigation [sections]="sections" [selectedQuestion]="selectedQuestion" [filterValue]="filterValue"
            [selectedReflexiveChoice]="selectedReflexiveChoice"
            [selectedSection]="selectedSection"></interview-navigation>
    </div>

</div>


<div *ngIf="selectedComponentType == 'none'" class="center-panel flex-fill d-flex flex-column">
    <div class="p-4 text-center text-muted">
        <img alt="" class="pb-3" src="./assets/images/no-data-placeholder.png" />
        <h6>No Questions Found</h6>
    </div>
    <!-- <div class="text-muted p-5 text-center">Please select a section to see the questions</div> -->
</div>

<div *ngIf="selectedComponentType != 'none'" class="center-panel flex-fill d-flex flex-column">
    <div class="center-panel-header">
        <ng-template
            [ngIf]="(selectedComponentType == 'section' || selectedComponentType == 'question') && selectedSection">
            <span class="d-inline-block center-panel-title">{{this.selectedSection?.name}}</span>
            <span class="d-inline-block float-right">
                <button (click)="expandAllQuestions = !expandAllQuestions" [disabled]="useThirdPartyQuestions"
                    class="btn btn-link btn-sm float-right custom" type="button">
                    <i [ngClass]="{'fa-angle-double-down': !expandAllQuestions, 'fa-angle-double-up': expandAllQuestions}"
                        aria-hidden="true" class="fas"></i>
                    {{(expandAllQuestions ? 'Collapse All' : 'Expand All')}}
                </button>
                <button (click)="addQuestion()" *ngIf="checkACL('W')" [disabled]="useThirdPartyQuestions"
                    [matTooltipDisabled]="!useThirdPartyQuestions" class="btn btn-link btn-sm float-right custom"
                    matTooltip="Disable Third Party Questions to enable" type="button">
                    <i aria-hidden="true" class="fa fa-plus-circle"></i> Add Question
                </button>
            </span>
        </ng-template>
        <ng-template
            [ngIf]="selectedComponentType == 'reflexiveChoice' || selectedComponentType == 'reflexiveQuestion'">
            <span class="d-inline-block">{{this.selectedReflexiveChoice?.value}}</span>
            <span class="d-inline-block float-right">
                <button (click)="expandAllQuestions = !expandAllQuestions"
                    class="btn btn-link btn-sm float-right custom" type="button">
                    <i [ngClass]="{'fa-angle-double-down': !expandAllQuestions, 'fa-angle-double-up': expandAllQuestions}"
                        aria-hidden="true" class="fas"></i>
                    {{(expandAllQuestions ? 'Collapse All' : 'Expand All')}}
                </button>
            </span>
        </ng-template>
    </div>
    <div class="center-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <div *ngIf="useThirdPartyQuestions" class="m-5 info_banner">
            <div class="info_banner-text" style="padding-top: 16px;">Third Party Questions are enabled.</div>
            <div class="info_banner-text" style="padding-bottom: 26px;">You cannot manually add questions to this
                section.
            </div>
        </div>
        <interview-preview *ngIf="!useThirdPartyQuestions" [constantLists]="constantLists"
            [expandAllQuestions]="expandAllQuestions" [previewQuestions]="previewQuestions"
            [selectedQuestion]="selectedQuestion" [selectedSection]="selectedSection"></interview-preview>
    </div>
</div>

<div class="right-panel d-flex flex-column bg-light">
    <div class="right-panel-body d-flex flex-column flex-fill">

        <!-- Section Properties -->
        <mat-tab-group *ngIf="selectedComponentType == 'section'" class="flex-fill">

            <mat-tab label="Properties">
                <form (ngSubmit)="saveSection()" [formGroup]="sectionForm" autocomplete="off"
                    class="flex-fill d-flex flex-column" novalidate>
                    <div class="property-box-header text-right px-4 py-2">
                        <span class="float-left property-box-title">Section Configuration</span>
                        <button (click)="selectedComponentType = 'none'"
                            *ngIf="checkACL('W') && !sectionForm.get('id').value"
                            class="btn btn-link btn-sm float-right custom" type="button">
                            <i aria-hidden="true" class="fa fa-times"></i> Cancel
                        </button>
                        <!-- <button type="button" class="btn btn-link btn-sm float-right custom" (click)="closeSectionForm()">
                                        <i aria-hidden="true" class="fa fa-times"></i> Cancel
                                    </button> -->
                        <button *ngIf="checkACL('W')" class="btn btn-link btn-sm float-right custom" type="submit">
                            <i aria-hidden="true" class="fa fa-check"></i> Save
                        </button>
                    </div>
                    <div class="flex-fill d-flex flex-column property-box-body p-4 overflow-y-auto custom-scrollbar">
                        <input formControlName="id" type="hidden">
                        <input formControlName="accountId" type="hidden">
                        <input formControlName="clientId" type="hidden">
                        <mat-form-field class="w-100 mb-1">
                            <mat-label>Section Name</mat-label>
                            <input autocomplete="off" formControlName="name" matInput>
                            <mat-error *ngIf="sectionForm.get('name').hasError('required')">
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100 mb-1">
                            <mat-label>Display Name</mat-label>
                            <input autocomplete="off" formControlName="header" matInput>
                        </mat-form-field>
                        <!--use thirdparty questions-->
                        <div *ngIf="enableThirdPartyQuestions" class="mb-2">
                            <div style="padding-bottom: 12px;">
                                <mat-slide-toggle (change)="toggleThirdPartyQuestions()"
                                    [checked]="useThirdPartyQuestions"
                                    [disabled]="selectedSection?.questions?.length > 0"
                                    [matTooltipDisabled]="selectedSection?.questions?.length == 0"
                                    matTooltip="Remove all questions in this section to enable"></mat-slide-toggle>
                                <span [ngClass]="{'disabled': selectedSection?.questions?.length > 0 }"
                                    class="ml-1 third-party-label">Use Third Party Questions</span>
                            </div>

                            <div *ngIf="useThirdPartyQuestions">
                                <!-- third party drop down -->
                                <mat-form-field style="width: 100%">
                                    <mat-label>Select Third Party Integration</mat-label>
                                    <mat-select formControlName="thirdPartyIntegration" style="width: 100%">
                                        <mat-option *ngFor="let tpI of thirdPartyIntegrations"
                                            [selected]="sectionForm.get('thirdPartyIntegration').value"
                                            [value]="tpI.id">{{tpI.name}}</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="sectionForm.get('thirdPartyIntegration').hasError('required')"><strong>required</strong></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="question-property-label mt-3">Restart Script</div>
                        <div class="position-relative">
                            <p-editor [readonly]='!this.checkACL("W")' [style]="{'height':'80px'}"
                                formControlName="restartScript">
                            </p-editor>
                            <button (click)="showPopupHTMLEditor('Restart Script')" *ngIf="checkACL('W')"
                                class="float-right btn btn-link btn-sm custom position-absolute expand-button"
                                title="Expand Restart Script" type="button">
                                <i aria-hidden="true" class="fas fa-expand-arrows-alt"></i>
                            </button>
                        </div>
                        <div class="question-property-label mt-3">Call Masking</div>
                        <div class="position-relative">
                            <mat-checkbox formControlName="isMasked">Mask call during this section</mat-checkbox>
                        </div>
                        <div class="question-property-label mt-3 flex ">
                            Section Hidden
                            <i aria-hidden="true" class="fas fa-info-circle"
                                title="Enable this setting if you want to hide this section from specific role(s) or rule"></i>
                        </div>
                        <div class="position-relative">
                            <label id="hideFor">Hide for:</label>
                            <mat-radio-group aria-labelledby="hideFor" class="column-radio-group"
                                formControlName="isSectionHidden">
                                <mat-radio-button value="none">None</mat-radio-button>
                                <mat-radio-button value="role">Specific Role(s)</mat-radio-button>
                                <mat-radio-button value="rule">Specific Rule</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="sectionForm.get('isSectionHidden').value=='role'">
                            <mat-form-field style="width:100%">
                                <mat-label>Role(s)</mat-label>
                                <mat-select disableOptionCentering formControlName="sectionHiddenRoles" multiple>
                                    <mat-option *ngFor="let role of roles"
                                        [value]="role.id">{{role.roleName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="sectionForm.get('isSectionHidden').value == 'rule'">
                            <mat-form-field style="width:100%">
                                <mat-label>Rule</mat-label>
                                <mat-select formControlName="sectionHiddenRule">
                                    <mat-option *ngFor="let rule of rules" [value]="rule.id">{{rule.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="question-property-label mt-3">Section Processing</div>
                        <div class="position-relative">
                            <mat-checkbox (change)="onSelectProcessingPopup($event.checked)"
                                formControlName="isProcessingPopupEnabled">Enable processing pop-up during section
                                submission
                            </mat-checkbox>
                        </div>
                        <mat-form-field class="w-100 mb-1">
                            <mat-label>Display Message</mat-label>
                            <input autocomplete="off" formControlName="displayMessage" matInput>
                        </mat-form-field>
                        <mat-form-field class="w-100 mb-1">
                            <mat-label>Timeout (in seconds)</mat-label>
                            <input autocomplete="off" formControlName="timeout" matInput>
                        </mat-form-field>
                        <div>
                            <auto-suggest [disabled]="sectionForm.get('sectionProcessingRuleId').disabled"
                                [list]="rules" dataKey="id" field="name" formControlName="sectionProcessingRuleId"
                                placeholder="Rule"></auto-suggest>
                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab *ngIf="sectionForm.get('id').value" label="Actions">
                <div class="flex-fill d-flex flex-column ">
                    <div class="property-box-header text-right px-4 py-2">
                        <span class="float-left property-box-title">Section Actions</span>
                        <button (click)="expandAllSectionActions = !expandAllSectionActions"
                            class="btn btn-link btn-sm float-right custom" type="button">
                            <i [ngClass]="{'fa-angle-double-down': !expandAllSectionActions, 'fa-angle-double-up': expandAllSectionActions}"
                                aria-hidden="true" class="fas"></i>
                            {{(expandAllSectionActions ? 'Collapse All' : 'Expand All')}}
                        </button>
                        <button (click)="addSectionAction();" *ngIf="checkACL('W')"
                            class="btn btn-link btn-sm float-right custom" type="button">
                            <i aria-hidden="true" class="fa fa-plus"></i> Add Action
                        </button>
                    </div>
                    <div *ngIf="sectionActions.length; else noSectionActionsPlaceholder" class="custom-scrollbar">
                        <div (cdkDropListDropped)="dropSectionAction($event)" cdkDropList class="drag-drop-list">
                            <action-v2 #sectionAction (onActionUpdated)="addedSectionAction()"
                                (onDeleteAction)="deleteSectionAction(action, i)"
                                *ngFor="let action of sectionActions; let i = index" [action]="action"
                                [actions]="actions" [expanded]="expandAllSectionActions" [rules]="rules"
                                actionParent="Section" cdkDrag cdkDragBoundary=".action-label">
                                <i aria-hidden="true" cdkDragHandle class="fa fa-grip-horizontal mr-2 pt-1"
                                    slot="drag-handle"></i>
                            </action-v2>
                        </div>
                    </div>
                    <ng-template #noSectionActionsPlaceholder>
                        <div class="p-4 text-center text-muted">
                            <img alt="" class="pb-3" src="./assets/images/no-data-placeholder.png" />
                            <h6>No Actions Found</h6>
                        </div>
                    </ng-template>
                </div>
            </mat-tab>

            <mat-tab label="API">
                <ng-template matTabContent>
                    <interview-section-api-settings (apiSettingsUpdated)="updateApiSettings($event)"
                        [accountInfo]="accountInfo" [rules]="rules"
                        [section]="selectedSection"></interview-section-api-settings>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

        <!-- Question Properties -->
        <mat-tab-group *ngIf="selectedComponentType == 'question' || selectedComponentType == 'reflexiveQuestion'"
            class="flex-fill">
            <mat-tab label="Properties">
                <form (ngSubmit)="saveQuestion()" [formGroup]="questionConfigForm" class="flex-fill d-flex flex-column"
                    novalidate>
                    <div class="property-box-header text-right px-4 py-2">
                        <span class="float-left property-box-title">Question Configuration</span>
                        <button (click)="showDeleteDialog=true"
                            *ngIf="checkACL('W') && !questionConfigForm.get('id').value"
                            class="btn btn-link btn-sm float-right custom" type="button">
                            <i aria-hidden="true" class="fa fa-times"></i> Cancel
                        </button>
                        <!-- <button type="button" class="btn btn-link btn-sm float-right custom" (click)="closeQuestionForm()">
                                                    <i aria-hidden="true" class="fa fa-times"></i> Cancel
                                                </button> -->
                        <button *ngIf="checkACL('W')" class="btn btn-link btn-sm float-right custom" type="submit">
                            <i aria-hidden="true" class="fa fa-check"></i> Save
                        </button>
                    </div>
                    <div class="flex-fill d-flex flex-column property-box-body p-4 overflow-y-auto custom-scrollbar">

                        <input formControlName="id" type="hidden">
                        <input formControlName="sectionsId" type="hidden">
                        <input formControlName="referenceId" type="hidden">

                        <div>
                            <mat-form-field class="w-100 mr-4">
                                <mat-label>Answer Type</mat-label>
                                <mat-select (selectionChange)="selectAnswerType()"
                                    [disabled]="selectedQuestion?.isReflexive || !this.checkACL('W')"
                                    formControlName='answerTypeId'>
                                    <mat-option *ngFor="let answerType of answerTypesFiltered"
                                        [value]="answerType.id">{{answerType.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="questionConfigForm.get('answerTypeId').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-slide-toggle *ngIf="selectedQuestion?.answerType != 'DisplayMessage'"
                                class="d-inline-block ml-4" formControlName='isRequired'>
                                &nbsp; Required Field
                            </mat-slide-toggle>
                        </div>

                        <mat-form-field class="">
                            <mat-label>Title</mat-label>
                            <input autocomplete="off" formControlName="questionTitle" matInput>
                            <mat-error *ngIf="questionConfigForm.get('questionTitle').hasError('required')">required
                            </mat-error>
                        </mat-form-field>
                        <map-keys-dropdown
                            *ngIf="selectedQuestion?.answerType != 'DisplayMessage' && selectedQuestion?.answerType != 'ActionButton'"
                            [allowedTypes]="[mapKeyTypeEnum.NonVirtual]" [disabled]="!this.checkACL('W')"
                            [displayList]="variableMapKeys" [formControl]="questionConfigForm.get('mapkeysId')"
                            [showRequiredError]="questionConfigForm.get('mapkeysId').hasError('required')"
                            formControlName="mapkeysId" placeholder="Mapkey">
                        </map-keys-dropdown>
                        <div class="question-property-label">Question Text</div>
                        <div class="position-relative">
                            <p-editor [readonly]="!this.checkACL('W')" [style]="{'height':'80px'}"
                                formControlName="questionText">
                            </p-editor>
                            <button (click)="showPopupHTMLEditor('Question Text')" *ngIf="checkACL('W')"
                                class="float-right btn btn-link btn-sm custom position-absolute expand-button"
                                title="Expand Question Text" type="button">
                                <i aria-hidden="true" class="fas fa-expand-arrows-alt"></i>
                            </button>
                            <mat-error *ngIf="questionConfigForm.get('questionText').hasError('required')">required
                            </mat-error>
                        </div>

                        <div class="question-property-label mt-3">Help Text</div>
                        <div class="position-relative">
                            <p-editor [readonly]="!this.checkACL('W')" [style]="{'height':'80px'}"
                                formControlName="helpText">
                            </p-editor>
                            <button (click)="showPopupHTMLEditor('Help Text')" *ngIf="checkACL('W')"
                                class="float-right btn btn-link btn-sm custom position-absolute expand-button"
                                title="Expand Help Text" type="button">
                                <i aria-hidden="true" class="fas fa-expand-arrows-alt"></i>
                            </button>
                        </div>


                        <h6 class="mt-3"></h6>

                        <!-- DisplayMessage -->
                        <div *ngIf="selectedQuestion?.answerType == 'DisplayMessage'"
                            formGroupName="answerTypeProperties">

                            <mat-form-field class="w-40 mr-5">
                                <mat-label>Display Type</mat-label>
                                <mat-select [disabled]="!this.checkACL('W')" formControlName='DisplayType'>
                                    <mat-option value="Inline">Inline</mat-option>
                                    <mat-option value="Popup">Popup</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.DisplayType').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-40">
                                <mat-label>Message Type</mat-label>
                                <mat-select [disabled]="!this.checkACL('W')" formControlName='MessageType'>
                                    <mat-option value="Information">Information</mat-option>
                                    <mat-option value="Warning">Warning</mat-option>
                                    <mat-option value="Error">Error</mat-option>
                                    <mat-option value="Success">Success</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MessageType').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- DateAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'DateAnswer'" formGroupName="answerTypeProperties">

                            <mat-form-field class="w-100">
                                <mat-label>Date Range Type</mat-label>
                                <mat-select [disabled]="!this.checkACL('W')" formControlName='DateRangeType'>
                                    <mat-option value="absolute">Absolute</mat-option>
                                    <mat-option value="relative">Relative to Today</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.DateRangeType').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>

                            <ng-template
                                [ngIf]="questionConfigForm.get('answerTypeProperties.DateRangeType').value == 'absolute'">
                                <mat-form-field class="w-40 mr-5">
                                    <mat-label>Min Date</mat-label>
                                    <input autocomplete="off" formControlName="MinDate" matInput type="date">
                                </mat-form-field>
                                <mat-form-field class="w-40">
                                    <mat-label>Max Date</mat-label>
                                    <input autocomplete="off" formControlName="MaxDate" matInput type="date">
                                </mat-form-field>
                            </ng-template>

                            <ng-template
                                [ngIf]="questionConfigForm.get('answerTypeProperties.DateRangeType').value == 'relative'">
                                <div>
                                    <mat-form-field class="w-40 mr-5">
                                        <mat-label>Min Type</mat-label>
                                        <mat-select (selectionChange)="changeDateType($event)"
                                            [disabled]="!this.checkACL('W')" formControlName='MinDateType'>
                                            <mat-option value="priorDays">Prior No Of Days</mat-option>
                                            <mat-option value="laterDays">Later No Of Days</mat-option>
                                            <mat-option value="priorYears">Prior No Of Years</mat-option>
                                            <mat-option value="laterYears">Later No Of Years</mat-option>
                                        </mat-select>
                                        <!-- <mat-error *ngIf="questionConfigForm.get('answerTypeId').hasError('required')">
                                                                <strong>required</strong>
                                                            </mat-error> -->
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="questionConfigForm.get('answerTypeProperties.MinDateType').value == 'priorDays' || questionConfigForm.get('answerTypeProperties.MinDateType').value == 'laterDays'"
                                        class="w-40">
                                        <mat-label>No of Days</mat-label>
                                        <input autocomplete="off" formControlName="MinNoOfDays" matInput type="number">
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="questionConfigForm.get('answerTypeProperties.MinDateType').value == 'priorYears' || questionConfigForm.get('answerTypeProperties.MinDateType').value == 'laterYears'"
                                        class="w-40">
                                        <mat-label>No of Years</mat-label>
                                        <input autocomplete="off" formControlName="MinNoOfYears" matInput type="number">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="w-40 mr-5">
                                        <mat-label>Max Type</mat-label>
                                        <mat-select (selectionChange)="changeDateType($event)"
                                            [disabled]="!this.checkACL('W')" formControlName='MaxDateType'>
                                            <mat-option value="priorDays">Prior No Of Days</mat-option>
                                            <mat-option value="laterDays">Later No Of Days</mat-option>
                                            <mat-option value="priorYears">Prior No Of Years</mat-option>
                                            <mat-option value="laterYears">Later No Of Years</mat-option>
                                        </mat-select>
                                        <!-- <mat-error *ngIf="questionConfigForm.get('answerTypeId').hasError('required')">
                                                                <strong>required</strong>
                                                            </mat-error> -->
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="questionConfigForm.get('answerTypeProperties.MaxDateType').value == 'priorDays' || questionConfigForm.get('answerTypeProperties.MaxDateType').value == 'laterDays'"
                                        class="w-40">
                                        <mat-label>No of Days</mat-label>
                                        <input autocomplete="off" formControlName="MaxNoOfDays" matInput type="number">
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="questionConfigForm.get('answerTypeProperties.MaxDateType').value == 'priorYears' || questionConfigForm.get('answerTypeProperties.MaxDateType').value == 'laterYears'"
                                        class="w-40">
                                        <mat-label>No of Years</mat-label>
                                        <input autocomplete="off" formControlName="MaxNoOfYears" matInput type="number">
                                    </mat-form-field>

                                </div>
                            </ng-template>

                        </div>


                        <!-- EFTDate -->
                        <div *ngIf="selectedQuestion?.answerType == 'EFTDate'" formGroupName="answerTypeProperties">
                            <map-keys-dropdown [displayList]="mapKeys"
                                [formControl]="questionConfigForm.get('answerTypeProperties.WednesdayMapkey')"
                                [showRequiredError]="questionConfigForm.get('answerTypeProperties.WednesdayMapkey').hasError('required')"
                                formControlName="WednesdayMapkey" placeholder="Wednesday Mapkey"></map-keys-dropdown>

                            <map-keys-dropdown [displayList]="mapKeys"
                                [formControl]="questionConfigForm.get('answerTypeProperties.SpecificdayMapkey')"
                                [showRequiredError]="questionConfigForm.get('answerTypeProperties.WednesdayMapkey').hasError('required')"
                                formControlName="SpecificdayMapkey"
                                placeholder="Specific Day Mapkey"></map-keys-dropdown>

                            <div>
                                <mat-form-field>
                                    <mat-label>Max Allowed Days</mat-label>
                                    <input formControlName="MaxAllowedDays" matInput type="number">
                                    <mat-error
                                        *ngIf="questionConfigForm.get('answerTypeProperties.MaxAllowedDays').hasError('min')">
                                        <strong>Maximum Allowed Days Can't be negative</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div>
                                <mat-form-field>
                                    <mat-label>Max Allowed Days to Back Date</mat-label>
                                    <input formControlName="MaxAllowedBackDate" matInput type="number">
                                    <mat-error
                                        *ngIf="questionConfigForm.get('answerTypeProperties.MaxAllowedBackDate').hasError('min')">
                                        <strong>Maximum Allowed Back Date Can't be negative</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div>
                                <div>
                                    <mat-slide-toggle formControlName="AllowToday">Allow today to be selected</mat-slide-toggle>
                                </div>
                            </div>
                        </div>


                        <!-- HeightAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'HeightAnswer'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-40 mr-5">
                                <mat-label>Min Inches</mat-label>
                                <input autocomplete="off" formControlName="MinInches" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MinInches').hasError('min')">
                                    <strong>Minimum Inches Can't be negative</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-40">
                                <mat-label>Max Inches</mat-label>
                                <input autocomplete="off" formControlName="MaxInches" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MaxInches').hasError('min')">
                                    <strong>Maximum Inches Can't be negative</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <!-- MultiLineAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'MultiLineAnswer'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100">
                                <mat-label>Placeholder Text</mat-label>
                                <input autocomplete="off" formControlName="Placeholder" matInput type="text">
                            </mat-form-field>
                            <mat-form-field class="w-40 mr-5">
                                <mat-label>Min Length</mat-label>
                                <input autocomplete="off" formControlName="MinLength" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MinLength').hasError('min')">
                                    <strong>Minimum Length Can't be negative</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-40">
                                <mat-label>Max Length</mat-label>
                                <input autocomplete="off" formControlName="MaxLength" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MaxLength').hasError('min')">
                                    <strong>Maximum Length Can't be negative</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <!-- NumericAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'NumericAnswer'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100 mb-2">
                                <mat-label>Placeholder Text</mat-label>
                                <input autocomplete="off" formControlName="Placeholder" matInput type="text">
                            </mat-form-field>
                            <mat-form-field class="w-40 mr-5">
                                <mat-label>Min Value</mat-label>
                                <input autocomplete="off" formControlName="MinValue" matInput type="number">
                            </mat-form-field>
                            <mat-form-field autocomplete="off" class="w-40">
                                <mat-label>Max Value</mat-label>
                                <input autocomplete="off" formControlName="MaxValue" matInput type="number">
                            </mat-form-field>
                            <mat-form-field autocomplete="off" class="w-40">
                                <mat-label>Max Decimal Places</mat-label>
                                <input autocomplete="off" formControlName="MaxNumberDecimalPlaces" matInput
                                    type="number">
                            </mat-form-field>
                            <div class="mt-2 mb-3">
                                <mat-slide-toggle class="d-inline-block" formControlName='IsCurrency'> &nbsp; Is
                                    Currency
                                </mat-slide-toggle>
                            </div>
                            <div class="mb-3">
                                <mat-slide-toggle class="d-inline-block" formControlName='IsMasked'>Mask
                                    Value
                                </mat-slide-toggle>
                            </div>
                            <mat-form-field *ngIf="questionConfigForm.get('answerTypeProperties.IsMasked').value"
                                class="w-40 mr-5">
                                <mat-label>Mask Character</mat-label>
                                <input autocomplete="off" formControlName="MaskCharacter" matInput maxlength="1"
                                    type="text">
                                <mat-hint>Default: *</mat-hint>
                            </mat-form-field>
                            <mat-form-field *ngIf="questionConfigForm.get('answerTypeProperties.IsMasked').value"
                                class="w-40">
                                <mat-label>Visible Characters</mat-label>
                                <input autocomplete="off" formControlName="MaskVisibleCharacters" matInput min="0"
                                    type="number">
                                <mat-hint>Default: 4</mat-hint>
                            </mat-form-field>
                        </div>

                        <!-- PhoneNumberAnswer RoutingNumberAnswer EmailAnswer SSNAnswer-->
                        <div *ngIf="selectedQuestion?.answerType == 'PhoneNumberAnswer' || selectedQuestion?.answerType == 'RoutingNumberAnswer' || selectedQuestion?.answerType == 'EmailAnswer' || selectedQuestion?.answerType == 'SSNAnswer'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100">
                                <mat-label>Placeholder Text</mat-label>
                                <input autocomplete="off" formControlName="Placeholder" matInput type="text">
                            </mat-form-field>
                        </div>

                        <!-- MultiChoiceMultiAnswer MultiChoiceSingleAnswer-->
                        <div *ngIf="(selectedQuestion?.answerType == 'MultiChoiceMultiAnswer' || selectedQuestion?.answerType == 'MultiChoiceMultiAnswerV2'  || selectedQuestion?.answerType == 'MultiChoiceSingleAnswer') && questionConfigForm.get('answerTypeProperties.DisplayType').value=='select'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100">
                                <mat-label>Placeholder Text</mat-label>
                                <input autocomplete="off" formControlName="Placeholder" matInput type="text">
                            </mat-form-field>
                        </div>

                        <!-- ShortAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'ShortAnswer'" formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100">
                                <mat-label>Placeholder Text</mat-label>
                                <input autocomplete="off" formControlName="Placeholder" matInput type="text">
                            </mat-form-field>
                            <mat-form-field class="w-40 mr-5">
                                <mat-label>Min Length</mat-label>
                                <input formControlName="MinLength" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MinLength').hasError('min')">
                                    <strong>Minimum Length Can't be negative</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-40">
                                <mat-label>Max Length</mat-label>
                                <input autocomplete="off" formControlName="MaxLength" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MaxLength').hasError('min')">
                                    <strong>Maximum Length Can't be negative</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-label>Allos Input of</mat-label>
                                <mat-select [disabled]="!this.checkACL('W')" formControlName='AllowInput'>
                                    <mat-option value="any">Any Character</mat-option>
                                    <mat-option value="numbers">Numbers Only</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.AllowInput').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <div class="mb-3">
                                <mat-slide-toggle class="d-inline-block" formControlName='IsMasked'>Mask
                                    Value
                                </mat-slide-toggle>
                            </div>
                            <mat-form-field *ngIf="questionConfigForm.get('answerTypeProperties.IsMasked').value"
                                class="w-40 mr-5">
                                <mat-label>Mask Character</mat-label>
                                <input autocomplete="off" formControlName="MaskCharacter" matInput maxlength="1"
                                    type="text">
                                <mat-hint>Default: *</mat-hint>
                            </mat-form-field>
                            <mat-form-field *ngIf="questionConfigForm.get('answerTypeProperties.IsMasked').value"
                                class="w-40">
                                <mat-label>Visible Characters</mat-label>
                                <input autocomplete="off" formControlName="MaskVisibleCharacters" matInput min="0"
                                    type="number">
                                <mat-hint>Default: 4</mat-hint>
                            </mat-form-field>
                        </div>


                        <!-- ActionButton -->
                        <div *ngIf="selectedQuestion?.answerType == 'ActionButton'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100">
                                <mat-label>Button Text</mat-label>
                                <input autocomplete="off" formControlName="ButtonText" matInput type="text">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.ButtonText').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- UploadFileActionButton -->
                        <div *ngIf="selectedQuestion?.answerType == 'UploadFileActionButton'"
                            formGroupName="answerTypeProperties">
                            <mat-form-field class="w-100">
                                <mat-label>Button Text</mat-label>
                                <input autocomplete="off" formControlName="ButtonText" matInput type="text">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.ButtonText').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <input formControlName="Tags" type="hidden" />
                            <object-tags [(tags)]="questionConfigForm.get('answerTypeProperties.Tags').value"
                                [account]="accountInfo">
                            </object-tags>
                            <mat-form-field class="w-100">
                                <mat-label>Allowed File Types</mat-label>
                                <mat-select formControlName='AllowedFileTypes' multiple>
                                    <mat-option value=".pdf">pdf</mat-option>
                                    <mat-option value=".jpg">jpg</mat-option>
                                    <mat-option value=".gif">gif</mat-option>
                                    <mat-option value=".png">png</mat-option>
                                    <mat-option value=".tiff">tiff</mat-option>
                                    <mat-option value=".wav">wav</mat-option>
                                    <mat-option value=".mp3">mp3</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.AllowedFileTypes').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-label>File Name Format</mat-label>
                                <input autocomplete="off" formControlName="FileNameFormat" matInput type="text">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.FileNameFormat').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-checkbox class="mr-2" formControlName="IncludeInCasePackage">Include In Case
                                Package
                            </mat-checkbox>
                            <map-keys-dropdown [disabled]="!this.checkACL('W')" [displayList]="variableMapKeys"
                                [formControl]="questionConfigForm.get('answerTypeProperties.UploadStatusMapKey')"
                                [showRequiredError]="questionConfigForm.get('answerTypeProperties.UploadStatusMapKey').hasError('required')"
                                formControlName="UploadStatusMapKey"
                                placeholder="Upload Status MapKey"></map-keys-dropdown>
                        </div>


                        <!-- MultiChoiceSingleAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'MultiChoiceSingleAnswer' && !selectedQuestion?.isReflexiveChildren"
                            formGroupName="answerTypeProperties">

                            <mat-form-field class="w-40 mr-5 mb-2">
                                <mat-label>Display Type</mat-label>
                                <mat-select [disabled]="!this.checkACL('W')" formControlName='DisplayType'>
                                    <mat-option value="radio">Radio Buttons</mat-option>
                                    <mat-option value="select">Drop Down List</mat-option>
                                    <mat-option value="button">Buttons</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.DisplayType').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>


                            <mat-form-field class="w-100 mb-2">
                                <mat-label>List Name</mat-label>
                                <mat-select (selectionChange)="getListValues($event.value)"
                                    [disabled]="!this.checkACL('W')" formControlName='listId'>
                                    <mat-option *ngFor="let mapKey of constantListMapKeys"
                                        [value]="mapKey.id">{{mapKey.entityHierarchy}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.listId').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="(selectedListValues?.length>0 && selectedQuestion?.answerType == 'MultiChoiceSingleAnswer' && !sectionApiEnabled && !MCMAReflexivesEnabled)"
                                class="reflexive-container">
                                <div class="question-property-label mt-3">Reflexive Questions</div>
                                <mat-slide-toggle class="d-inline-block mb-4"
                                    formControlName="indentReflexiveQuestions">
                                    &nbsp; Indent Reflexive Questions in Interview
                                </mat-slide-toggle>
                                <div *ngIf="variantsForListValues" class="informationVariant">
                                    <mat-icon svgIcon="information-outline"></mat-icon>
                                    List has variants. Default list
                                    shown.
                                </div>
                                <div>
                                    <div *ngFor="let listValue of selectedListValues"
                                        class="reflexive-choice-container">
                                        <div class="reflexive-choice-header">
                                            <button (click)="listValue.expanded = !listValue.expanded"
                                                class="btn btn-link btn-sm float-left expand-trigger" type="button">
                                                <i [ngClass]="{'fa-angle-right': !listValue.expanded, 'fa-angle-down': listValue.expanded}"
                                                    aria-hidden="true" class="fa"></i>
                                            </button>
                                            <span class="d-inline-block">{{listValue.displayName}}</span>
                                            <button (click)="listValue.showForm = true"
                                                *ngIf="checkACL('W') && listValue.expanded"
                                                class="btn btn-link btn-sm float-right custom" type="button">
                                                <i aria-hidden="true" class="fa fa-plus"></i> Add Question
                                            </button>
                                        </div>
                                        <div (cdkDropListDropped)="dropReflexive($event, listValue)"
                                            *ngIf="listValue.expanded" cdkDropList class="drag-drop-list">
                                            <div *ngFor="let rq of selectedQuestion.reflexiveQuestions; let qIndex = index;"
                                                cdkDrag cdkDragBoundary=".drag-drop-list">
                                                <div *ngIf="rq.answerValueId == listValue.id">
                                                    <div class="drag-drop-box">
                                                        <div *cdkDragPlaceholder class="drag-drop-custom-placeholder">
                                                        </div>
                                                        <span
                                                            class="d-inline-block float-left">{{getQuestionMapKeyName(rq.refQuestionId)}}</span>
                                                        <div class="float-right">
                                                            <span class="ml-1 drag-handle">
                                                                <i aria-hidden="true" cdkDragHandle
                                                                    class="fa fa-grip-horizontal mr-2 pt-1"></i>
                                                            </span>
                                                            <button (click)="removeReflexiveQuestion(qIndex)"
                                                                *ngIf="checkACL('W')" class="btn btn-link btn-sm"
                                                                type="button">
                                                                <i aria-hidden="true" class="fa fa-minus-circle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="listValue?.showForm" class="reflexive-choice-form">
                                                <mat-form-field class="w-65">
                                                    <mat-label>Question</mat-label>
                                                    <mat-select #addRefQuestion [disabled]="!this.checkACL('W')">
                                                        <mat-option
                                                            *ngFor="let question of getPossibleReflexiveQuestions(listValue.id)"
                                                            [value]="question.id">{{question.mapKeyName}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <button
                                                    (click)="addReflexiveQuestion(addRefQuestion.value, listValue.id); addRefQuestion.value = null;"
                                                    [disabled]="!addRefQuestion?.value"
                                                    class="btn btn-link btn-sm custom" type="button">
                                                    <i aria-hidden="true" class="fa fa-check"></i> Add
                                                </button>
                                                <button (click)="listValue.showForm = false"
                                                    class="btn btn-link btn-sm custom" type="button">
                                                    <i aria-hidden="true" class="fa fa-times"></i> Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(selectedListValues?.length>0 && !sectionApiEnabled && MCMAReflexivesEnabled)"
                                class="reflexive-container">
                                <div class="question-property-label mt-3">Reflexive Questions</div>
                                <mat-slide-toggle class="d-inline-block mb-4"
                                    formControlName="indentReflexiveQuestions">
                                    &nbsp; Indent Reflexive Questions in Interview
                                </mat-slide-toggle>
                                <div *ngIf="variantsForListValues" class="informationVariant">
                                    <mat-icon svgIcon="information-outline"></mat-icon>
                                    List has variants. Default list
                                    shown.
                                </div>
                                <div>
                                    <div *ngFor="let listValue of selectedListValues"
                                        class="reflexive-choice-container">
                                        <div class="reflexive-choice-header">
                                            <button
                                                (click)="listValue.expanded = !listValue.expanded;populatePossibleQuestions(listValue)"
                                                class="btn btn-link btn-sm float-left expand-trigger" type="button">
                                                <i [ngClass]="{'fa-angle-right': !listValue.expanded, 'fa-angle-down': listValue.expanded}"
                                                    aria-hidden="true" class="fa"></i>
                                            </button>
                                            <span
                                                (click)="listValue.expanded = !listValue.expanded;populatePossibleQuestions(listValue)"
                                                class="d-inline-block reflexiveQuestionTitle">{{listValue.displayName}}
                                            </span><span
                                                *ngIf="listValue.reflexiveQuestions && listValue.reflexiveQuestions.length > 0"
                                                class="float-right">
                                                <span class="chipspan">
                                                    <mat-chip-list>
                                                        <mat-chip [color]=" primary" [highlighted]="true">
                                                            {{listValue.reflexiveQuestions.length}}</mat-chip>
                                                    </mat-chip-list>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="listValue.expanded">
                                            <div (cdkDropListDropped)="dropReflexiveV2($event,listValue)" cdkDropList>
                                                <div *ngFor="let reflexiveQuestion of listValue.reflexiveQuestions; let qIndex = index;"
                                                    cdkDrag class="d-flex flex-row align-items-center flex-fill">
                                                    <div class="d-flex flex-row align-items-center flex-fill">
                                                        <mat-icon cdkDragHandle
                                                            class="btn-drag padding-right alignWithParentText">drag_indicator
                                                        </mat-icon>
                                                        <mrs-dropdown-primary
                                                            (selectedValueChange)="handleSelectedValueChange($event, listValue, reflexiveQuestion)"
                                                            [(selectedValue)]='reflexiveQuestion.refQuestionId'
                                                            [label]='Question'
                                                            [values]="reflexiveQuestion.possibleQuestions"
                                                            class="flex-grow-1">
                                                        </mrs-dropdown-primary>
                                                        <mat-icon (click)="removeReflxiveQuestionV2(listValue, qIndex)"
                                                            aria-label="remove reflexive question"
                                                            class="padding-left reflexiveQuestionTitle">remove_circle_outline
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <button (click)="addReflexiveQuestionV2(listValue)"
                                                *ngIf="listValue.possibleQuestions && listValue.possibleQuestions.length > 0 && (!listValue.reflexiveQuestions || listValue.reflexiveQuestions.length < listValue.possibleQuestions.length)"
                                                class="btn btn-link btn-sm custom alignWithParentText" type="button">
                                                <i aria-hidden="true" class="fa fa-plus"></i> Add Question
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- MultiChoiceMultiAnswer -->
                        <div *ngIf="selectedQuestion?.answerType == 'MultiChoiceMultiAnswer' || selectedQuestion?.answerType == 'MultiChoiceMultiAnswerV2'"
                            formGroupName="answerTypeProperties">

                            <mat-form-field class="w-40 mr-5 mb-2">
                                <mat-label>Display Type</mat-label>
                                <mat-select [disabled]="!this.checkACL('W')" formControlName='DisplayType'>
                                    <mat-option value="checkbox">Check Boxes</mat-option>
                                    <mat-option value="select">Drop Down List</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="w-100 mb-2">
                                <mat-label>List Name</mat-label>
                                <mat-select (selectionChange)="getListValues($event.value)"
                                    [disabled]="!this.checkACL('W')" formControlName='listId'>
                                    <mat-option *ngFor="let mapKey of constantListMapKeys"
                                        [value]="mapKey.id">{{mapKey.entityHierarchy}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class=" mt-2">
                                <mat-slide-toggle class="d-inline-block" formControlName='AllowNewValues'> &nbsp; Allow
                                    New Values
                                </mat-slide-toggle>
                            </div>
                            <div *ngIf="(selectedListValues?.length>0 && !sectionApiEnabled && MCMAReflexivesEnabled)"
                                class="reflexive-container">
                                <div class="question-property-label mt-3">Reflexive Questions</div>
                                <mat-slide-toggle *ngIf="MCMAReflexivesEnabled" class="d-inline-block mb-4"
                                    formControlName="indentReflexiveQuestions">
                                    &nbsp; Indent Reflexive Questions in Interview
                                </mat-slide-toggle>
                                <div *ngIf="variantsForListValues && selectedQuestion?.answerType == 'MultiChoiceMultiAnswerV2' "
                                    class="informationVariant">
                                    <mat-icon svgIcon="information-outline"></mat-icon>
                                    List has variants. Default list
                                    shown.
                                </div>
                                <div *ngIf="variantsForListValues && selectedQuestion?.answerType == 'MultiChoiceMultiAnswer' "
                                    class="informationVariant">
                                    <mat-icon svgIcon="information-outline"></mat-icon>
                                    MCMA is obsolete. Use MCMAV2
                                    for variants.
                                </div>
                                <div>
                                    <div *ngFor="let listValue of selectedListValues"
                                        class="reflexive-choice-container">
                                        <div (click)="listValue.expanded = !listValue.expanded;populatePossibleQuestions(listValue)"
                                            class="reflexive-choice-header">
                                            <button class="btn btn-link btn-sm float-left expand-trigger" type="button">
                                                <i [ngClass]="{'fa-angle-right': !listValue.expanded, 'fa-angle-down': listValue.expanded}"
                                                    aria-hidden="true" class="fa"></i>
                                            </button>
                                            <span
                                                class="d-inline-block reflexiveQuestionTitle">{{listValue.displayName}}</span>
                                            <span
                                                *ngIf="listValue.reflexiveQuestions && listValue.reflexiveQuestions.length > 0"
                                                class="float-right">
                                                <span class="chipspan">
                                                    <mat-chip-list>
                                                        <mat-chip [color]=" primary" [highlighted]="true">
                                                            {{listValue.reflexiveQuestions.length}}</mat-chip>
                                                    </mat-chip-list>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="listValue.expanded">
                                            <div (cdkDropListDropped)="dropReflexiveV2($event,listValue)" cdkDropList>
                                                <div *ngFor="let reflexiveQuestion of listValue.reflexiveQuestions; let qIndex = index;"
                                                    cdkDrag class="d-flex flex-row align-items-center flex-fill">
                                                    <div class="d-flex flex-row align-items-center flex-fill">
                                                        <mat-icon cdkDragHandle
                                                            class="btn-drag padding-right alignWithParentText">drag_indicator
                                                        </mat-icon>
                                                        <mrs-dropdown-primary
                                                            (selectedValueChange)="handleSelectedValueChange($event, listValue, reflexiveQuestion)"
                                                            [(selectedValue)]='reflexiveQuestion.refQuestionId'
                                                            [label]='Question'
                                                            [values]="reflexiveQuestion.possibleQuestions"
                                                            class="flex-grow-1">
                                                        </mrs-dropdown-primary>
                                                        <mat-icon (click)="removeReflxiveQuestionV2(listValue, qIndex)"
                                                            aria-label="remove reflexive question"
                                                            class="padding-left reflexiveQuestionTitle">remove_circle_outline
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <button (click)="addReflexiveQuestionV2(listValue)"
                                                *ngIf="listValue.possibleQuestions && listValue.possibleQuestions.length > 0 && (!listValue.reflexiveQuestions || listValue.reflexiveQuestions.length < listValue.possibleQuestions.length)"
                                                class="btn btn-link btn-sm custom alignWithParentText" type="button">
                                                <i aria-hidden="true" class="fa fa-plus"></i> Add Question
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- RepeatBlock -->
                        <div *ngIf="selectedQuestion?.answerType == 'RepeatBlock'" formGroupName="answerTypeProperties">

                            <mat-form-field class="w-40">
                                <mat-label>Max Repeats</mat-label>
                                <input autocomplete="off" formControlName="MaxRepeatBlocks" matInput type="number">
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MaxRepeatBlocks').hasError('required')">
                                    <strong>required</strong>
                                </mat-error>
                                <mat-error
                                    *ngIf="questionConfigForm.get('answerTypeProperties.MaxRepeatBlocks').hasError('min')">
                                    <strong>Repeats Should be Minimum 2</strong>
                                </mat-error>
                            </mat-form-field>

                            <div>
                                <div class="reflexive-choice-header">
                                    <div class="question-property-label d-inline-block float-left ml-2">Repeat
                                        Questions
                                    </div>
                                    <button (click)="showRepeatBlockQuestionForm = true" *ngIf="checkACL('W')"
                                        class="btn btn-link btn-sm float-right custom" type="button">
                                        <i aria-hidden="true" class="fa fa-plus"></i> Add Question
                                    </button>
                                </div>
                                <div (cdkDropListDropped)="dropRepeatBlock($event)" cdkDropList>
                                    <div *ngFor="let rq of selectedQuestion.repeatBlockQuestions; let qIndex = index;"
                                        cdkDrag cdkDragBoundary=".reflexive-choice-body">
                                        <div *cdkDragPlaceholder class="drag-drop-custom-placeholder"></div>
                                        <div class="drag-drop-box">
                                            <span
                                                class="d-inline-block float-left">{{getQuestionMapKeyName(rq.repeatedQuestionId)}}</span>
                                            <div class="float-right">
                                                <span class="ml-1 drag-handle">
                                                    <i aria-hidden="true" cdkDragHandle
                                                        class="fa fa-grip-horizontal mr-2 pt-1"></i>
                                                </span>
                                                <button (click)="removeRepeatBlockQuestion(qIndex)"
                                                    *ngIf="checkACL('W')" class="btn btn-link btn-sm" type="button">
                                                    <i aria-hidden="true" class="fa fa-minus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!!showRepeatBlockQuestionForm" class="reflexive-choice-form">
                                        <mat-form-field class="w-65">
                                            <mat-label>Question</mat-label>
                                            <mat-select #addRepBlockQuestion [disabled]="!this.checkACL('W')">
                                                <mat-option *ngFor="let question of getPossibleRepeatBlockQuestions()"
                                                    [value]="question.id">
                                                    {{question.mapKeyName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button
                                            (click)="addRepeatBlockQuestion(addRepBlockQuestion.value); addRepBlockQuestion.value = null;"
                                            [disabled]="!addRepBlockQuestion?.value" class="btn btn-link btn-sm custom"
                                            type="button">
                                            <i aria-hidden="true" class="fa fa-check"></i> Add
                                        </button>
                                        <button (click)="showRepeatBlockQuestionForm = false"
                                            class="btn btn-link btn-sm custom" type="button">
                                            <i aria-hidden="true" class="fa fa-times"></i> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Pre-fill Answer-->
                        <div *ngIf="selectedIsPreFill() && prefillAnswersSetting" id="prefillAnswer"
                            style="margin-top: .75rem; margin-bottom: .75rem">
                            <prefill-answer [questions]="prefillQuestions" [excludeQuestions]="[selectedQuestion?.id]"
                                [fromQuestion]="questionConfigForm.get('fromQuestion')?.value"
                                [populateWith]="questionConfigForm.get('populateWith')?.value"
                                (fromQuestionSelected)="fromQuestionSelected($event)"
                                (prefillSelected)="questionConfigForm.get('populateWith')?.setValue($event)"></prefill-answer>
                        </div>

                        <div>
                            <auto-suggest [list]="rules" dataKey="id" field="name" formControlName="transcriptRuleId"
                                placeholder="Transcript Rule"></auto-suggest>

                        </div>
                        <div class="m-5">&nbsp;</div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab *ngIf="questionConfigForm.get('id').value && !sectionApiEnabled" class="" label="Actions">
                <div class="flex-fill d-flex flex-column action-label">

                    <div class="property-box-header text-right px-4 py-2">
                        <span class="float-left property-box-title">Question Actions</span>
                        <button (click)="expandAllQuestionActions = !expandAllQuestionActions"
                            class="btn btn-link btn-sm float-right custom" type="button">
                            <i [ngClass]="{'fa-angle-double-down': !expandAllQuestionActions, 'fa-angle-double-up': expandAllQuestionActions}"
                                aria-hidden="true" class="fas"></i>
                            {{(expandAllQuestionActions ? 'Collapse All' : 'Expand All')}}
                        </button>
                        <button (click)="addQuestionAction();" *ngIf="checkACL('W')"
                            class="btn btn-link btn-sm float-right custom" type="button">
                            <i aria-hidden="true" class="fa fa-plus"></i> Add Action
                        </button>
                    </div>

                    <div *ngIf="questionActions.length; else noQuestionActionsPlaceholder"
                        class="flex-fill d-flex flex-column property-box-body p-4 overflow-y-auto custom-scrollbar action-label">
                        <div (cdkDropListDropped)="dropQuestionAction($event)" cdkDropList class="drag-drop-list">
                            <action-v2 #questionAction (onActionUpdated)="addedQuestionAction()"
                                (onDeleteAction)="deleteQuestionAction(action, i)"
                                *ngFor="let action of questionActions; let i = index" [action]="action"
                                [actions]="actions" [expanded]="expandAllQuestionActions" [rules]="rules"
                                actionParent="Questions" cdkDrag cdkDragBoundary=".action-label">
                                <i aria-hidden="true" cdkDragHandle class="fa fa-grip-horizontal mr-2 pt-1"
                                    slot="drag-handle"></i>
                            </action-v2>
                        </div>

                    </div>

                    <ng-template #noQuestionActionsPlaceholder>
                        <div class=" p-4 text-center text-muted ">
                            <img alt=" " class="pb-3 " src="./assets/images/no-data-placeholder.png " />
                            <h6>No Actions Found</h6>
                        </div>
                    </ng-template>

                </div>
            </mat-tab>


        </mat-tab-group>


    </div>
</div>

<p-dialog [(visible)]="showDeleteDialog" [responsive]="true" appendTo="body" header="Confirm Delete" modal="true"
    width="300">
    {{message}} Are you sure you want to cancel adding the question?
    <p-footer>
        <button (click)="closeQuestionProperties()" class="btn btn-danger mr-3" type="button">
            <i aria-hidden="true" class="fa fa-check "></i> Yes
        </button>
        <button (click)="showDeleteDialog=false" class="btn btn-outline-secondary" type="button">
            <i aria-hidden="true" class="fa fa-times "></i> No
        </button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="popupHtmlEditor.isVisible" [closable]="false" [closeOnEscape]="false" [resizable]="false"
    appendTo="body" header="Editor" modal="true">
    <p-editor [(ngModel)]="popupHtmlEditor.value" ngDefaultControl
        [style]="{'height':'400px', 'width':'800px'}"></p-editor>
    <p-footer>
        <button (click)="closePopupHtmlEditor()" class="mr-1" color="basic" mat-button>
            <i aria-hidden="true" class="fa fa-times"></i> Close
        </button>
    </p-footer>
</p-dialog>
