enum RolesEnum {
    Agent = 'Agent',
    Applicant = 'Applicant',
    ClientCSR = 'Client CSR',
    ClientSupervisor = 'Client Supervisor',
    Processor = 'Processor',
    TIRepresentative = 'TI Representative',
    Underwriter = 'Underwriter',
    RgaUnderwriter = 'RGA Underwriter',
    CSR = 'CSR',
    Supervisor = 'Supervisor'
}

export { RolesEnum };
